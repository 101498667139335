import {useLogout, useUpdateUser} from 'api/users';
import classNames from 'classnames';
import IconSVG from 'components/ui/Icon/IconSVG';
import {LEGAL_FORM_NATURAL_PERSON, PATH} from 'constants/common';
import {useAuth} from 'context/auth-context';
import {useBusinessPartner} from 'context/business-partner-context';
import {LocalizeLanguages, useLanguage} from 'context/language-context';
import React, {Ref, useMemo} from 'react';
import {Dropdown} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {UserMenuHierarchy, UserMenuItemType} from 'types/userMenu';

import styles from './UserMenu.module.sass';

const UserMenu = ({white}: {white?: boolean}) => {
    const {activeBusinessPartner} = useBusinessPartner();
    const {updateLanguage, availableLanguages, selectedLanguage} =
        useLanguage();
    const {user, logout} = useAuth();
    const {mutate: updateUser} = useUpdateUser();

    const {setSelectedBpId} = useBusinessPartner();
    const {mutate: sessionLogout} = useLogout();
    const isAdmin = activeBusinessPartner?.permission?.admin;

    const name = useMemo(() => `${user.firstName} ${user.lastName}`, [user]);
    const onLogout = () => {
        sessionLogout(undefined, {
            onSuccess: () => {
                setSelectedBpId(null);
                logout();
            },
        });
    };

    if (!user) {
        return null;
    }

    const changeLanguage = (lang: LocalizeLanguages) => {
        updateLanguage(lang.code);
        updateUser({'@id': user['@id'], language: lang.id});
    };

    const notOnlyCardsPermissionUser: UserMenuHierarchy = [
        {
            label: 'Payment instructions',
            type: UserMenuItemType.LINK,
            to: PATH.SETTLEMENT_ACCOUNTS,
            disabled:
                activeBusinessPartner.sandbox ||
                !activeBusinessPartner.identified,
            disabledTooltip: 'You have to get onboarded first',
        },
        {
            label:
                activeBusinessPartner.legalForm === LEGAL_FORM_NATURAL_PERSON
                    ? 'Account settings'
                    : 'Company settings',
            type: UserMenuItemType.LINK,
            to: PATH.ADMIN,
            disabled: !isAdmin,
            disabledTooltip: 'You are not admin for this Business partner.',
            renderCondition: () => activeBusinessPartner?.sandbox !== true,
        },
        {
            label: 'Add company',
            type: UserMenuItemType.LINK,
            to: PATH.ADDITIONAL_BUSINESS_PARTNER,
        },
    ];

    const userMenu: UserMenuHierarchy = [
        ...(!activeBusinessPartner?.permission?.onlyCardUser
            ? notOnlyCardsPermissionUser
            : []),
        {
            label: 'User settings',
            type: UserMenuItemType.LINK,
            to: PATH.SETTINGS,
        },
        {
            label: 'Language',
            type: UserMenuItemType.SUBMENU,
            children: availableLanguages.map(l => {
                return {
                    label: l.name,
                    type: UserMenuItemType.BUTTON,
                    action: () => changeLanguage(l),
                    className:
                        selectedLanguage === l.code ? 'activeLanguage' : '',
                };
            }),
        },
        {
            label: 'Help center',
            type: UserMenuItemType.SUBMENU,
            children: [
                {
                    label: 'FAQ',
                    type: UserMenuItemType.BUTTON,
                    action: () =>
                        window.open(
                            `https://help.amnistreasury.com/${
                                selectedLanguage === 'de' ? 'de' : 'en'
                            }`,
                            '_blank',
                        ),
                },
                {
                    label: 'Product Roadmap',
                    type: UserMenuItemType.BUTTON,
                    action: () =>
                        window.open(
                            'https://ideas.amnistreasury.com/tabs/1-under-consideration',
                            '_blank',
                        ),
                },
                {
                    label: 'Submit an idea',
                    type: UserMenuItemType.BUTTON,
                    action: () =>
                        window.open(
                            'https://ideas.amnistreasury.com/tabs/1-under-consideration/submit-idea',
                            '_blank',
                        ),
                },
                {
                    label: 'Live Chat',
                    type: UserMenuItemType.BUTTON,
                    action: () => window.Intercom('show'),
                },
            ],
        },
        ...(!activeBusinessPartner?.permission?.onlyCardUser
            ? ([
                  {
                      type: UserMenuItemType.LINK,
                      label: 'Upgrade account',
                      to: PATH.PRICING,
                      renderComponent: () => (
                          <span
                              className={classNames(
                                  styles.pricingButton,
                                  'btn btn-primary',
                              )}
                          >
                              <IconSVG
                                  name="upgrade"
                                  customDimensions
                                  height="1rem"
                                  className="mr-2"
                              />
                              Upgrade
                          </span>
                      ),
                  },
              ] as UserMenuHierarchy)
            : []),
        {
            type: UserMenuItemType.DIVIDER,
        },
        {
            label: 'Logout',
            type: UserMenuItemType.BUTTON,
            action: onLogout,
        },
    ];

    const DisabledTooltipWrapper = ({
        children,
        message,
        disabled = false,
    }: {
        children: JSX.Element;
        message?: string;
        disabled: boolean;
    }) => {
        if (!message) {
            return children;
        }

        return <span title={disabled ? message : ''}>{children}</span>;
    };

    const ParentMenu = React.forwardRef(
        (
            {
                children,
                onClick,
                className,
            }: {
                children: React.ReactNode;
                onClick?: (args?: unknown) => unknown;
                className?: string;
            },
            ref,
        ) => {
            return (
                <button
                    tabIndex={0}
                    className={classNames(styles.dropdownToggle, className)}
                    ref={ref as Ref<HTMLButtonElement>}
                    onClick={e => {
                        e.preventDefault();
                        return onClick?.(e);
                    }}
                >
                    {children}
                </button>
            );
        },
    );

    const createMenu = (menuItems: UserMenuHierarchy) => {
        return menuItems.map((m, i) => {
            if (m.renderCondition && !m.renderCondition()) {
                return null;
            }
            switch (m.type) {
                case UserMenuItemType.LINK:
                    return (
                        <DisabledTooltipWrapper
                            message={m.disabledTooltip}
                            disabled={!!m.disabled}
                            key={m.label}
                        >
                            <Dropdown.Item
                                as={Link}
                                to={m.to}
                                disabled={m.disabled}
                                className={m.className}
                            >
                                {m.renderComponent
                                    ? m.renderComponent()
                                    : m.label}
                            </Dropdown.Item>
                        </DisabledTooltipWrapper>
                    );
                case UserMenuItemType.BUTTON:
                    return (
                        <DisabledTooltipWrapper
                            message={m.disabledTooltip}
                            disabled={!!m.disabled}
                            key={m.label}
                        >
                            <Dropdown.Item
                                onClick={m.action}
                                disabled={m.disabled}
                                className={m.className}
                            >
                                {m.label}
                            </Dropdown.Item>
                        </DisabledTooltipWrapper>
                    );
                case UserMenuItemType.SUBMENU:
                    return (
                        <DisabledTooltipWrapper
                            message={m.disabledTooltip}
                            disabled={!!m.disabled}
                            key={m.label}
                        >
                            <Dropdown drop="right">
                                <Dropdown.Toggle id={m.label} as={ParentMenu}>
                                    {m.label}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {createMenu(m.children)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </DisabledTooltipWrapper>
                    );
                case UserMenuItemType.DIVIDER:
                    return <Dropdown.Divider key={`${i}/${m.type}`} />;
                default:
                    throw new Error('UserMenuItemType not implemented');
            }
        });
    };

    return (
        <Dropdown drop="down">
            <Dropdown.Toggle
                id="user-menu-dropdown"
                as={ParentMenu}
                className={classNames(styles.userMenuDropdown, {
                    [styles.white]: white,
                })}
            >
                <span data-notranslate>{name}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.dropdownMenu}>
                {createMenu(userMenu)}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UserMenu;
