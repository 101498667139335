const Bell = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M4.5 18.885v-1.5h1.808V9.923c0-1.344.415-2.533 1.245-3.567a5.518 5.518 0 0 1 3.197-1.982V3.75c0-.347.121-.642.364-.885S11.652 2.5 12 2.5s.642.122.886.365c.243.243.365.538.365.885v.623a5.518 5.518 0 0 1 3.197 1.983c.83 1.034 1.245 2.223 1.245 3.567v7.462H19.5v1.5h-15Zm7.498 2.808c-.497 0-.923-.177-1.276-.531a1.743 1.743 0 0 1-.53-1.277h3.616c0 .499-.178.925-.532 1.278-.354.353-.78.53-1.278.53Zm-4.19-4.308h8.384V9.923c0-1.157-.409-2.145-1.228-2.964-.818-.819-1.806-1.228-2.964-1.228s-2.146.41-2.964 1.228c-.819.819-1.228 1.807-1.228 2.964v7.462Z"
        />
    </svg>
);

export default Bell;
