export const USER_CACHE = 'user';
export const ALL_CONTACTS_LIST_CACHE = 'all_contacts_list_cache';
export const BUSINESS_INDUSTRIES = 'business-industries';
export const CONTACTS_LIST_CACHE = 'contacts_list';
export const CONTACT_DETAILS_CACHE = 'contact_details';
export const CONTACT_TRANSACTIONS_CACHE = 'contact_transactions';
export const CONTACT_BANK_ACCOUNTS_CACHE = 'contact_bank_accounts';
export const CONTACT_PREVIEW_INVITE_EMAIL = 'contact_preview_invite_email';
export const CONTACTS_PAYMENTS_CACHE = 'contacts_payments_cache';
export const MOST_USED_CONTACTS_CACHE = 'most_used_contacts_cache';
export const PENDING_TRANSACTIONS_CACHE = 'pending_fx_transactions';
export const PENDING_TRANSACTIONS_DETAILS_LIMIT_ORDERDS_CACHE =
    'pending_fx_transactions_details_limit_orders';
export const TRANSACTIONS_CACHE = 'transactions';
export const TRANSACTIONS_TAG_REPORT_CACHE = 'transactions_tag_report';
export const TRANSACTION_DETAILS_CACHE = 'transactions_details';
export const CARD_TRANSACTIONS_DETAILS_CACHE = 'card_transactions_details';
export const WALLET_CACHE = 'WALLET_CACHE';
export const WALLETS_CACHE = 'wallets';
export const BP_WALLETS_COLLATERAL_CACHE =
    'business_partner_wallets_collateral';
export const BP_VALUATIONS_CACHE = 'business_partner_valuations';
export const BP_VALUATION_RESULTS_CACHE = 'business_partner_valuations';
export const BP_VOLUME_STATS_CACHE = 'business_partner_volume_stats';
export const PAYMENT_FIELD_CACHE = 'payment_field_settings';
export const PAYMENT_FILES_CACHE = 'payment_files';
export const PAYMENT_FILE_CACHE = 'payment_file';
export const PAYMENT_FILE_DETAILS_CACHE = 'payment_file_details';
export const BP_CONTRACTS_CACHE = 'business_partner_contracts';
export const COMPANY_CACHE = 'company_data';
export const USER_CRP_CACHE = 'user_crp';
export const CRP_LIST_CACHE = 'crps_list';
export const CRP_BP_LIST_CACHE = 'crp_bps_list';
export const CRP_BP_CACHE = 'crp_bp';
export const IDENTIFICATION_LIST_CACHE = 'identifications';
export const AMNIS_BANK_ACCOUNT = 'amnis-bank-account';
export const AMNIS_BANK_ACCOUNTS = 'amnis-bank-accounts';
export const PERMISSIONS_CACHE = 'permissions_cache';
export const DASHBOARD_NOTIFICATION_CACHE = 'dashboard_notification_cache';
export const INVITE_HASH_CACE = 'invite_hash_cache';
export const BP_LIST_CACHE = 'bp_list';
export const SINGLE_BP_CACHE = 'single_bp_cache';
export const PACKAGES_CACHE = 'packages_cache';
export const BANNERS_CACHE = 'banners';
export const MERCURE_TOKEN_CACHE = 'mercure_token_cache';
export const GET_AUTH_FACTORS = 'get_auth_factors';
export const GET_FUTURAE_INFO = 'get_futurae_info';
export const GET_QR_AUTH_STATUS = 'get_qr_auth_status';
export const RATE_ALERTS = 'rate_alerts';
export const RATE_ALERT = 'rate_alert';
export const RATE_ALERT_CURRENCY_PAIRS = 'rate_alert_currency_pairs';
export const LEGAL_FORM = 'legal_form';
export const CRP_DOCUMENTS = 'crp_documents';
export const BP_DOCUMENTS = 'bp_documents';
export const PAYMENT_GROUPS = 'payment_groups';
export const ALL_PAYMENT_GROUPS = 'all_payment_groups';
export const MULTI_PAYMENT = 'multi_payment';
export const MULTI_PAYMENTS = 'multi_payments';
export const MULTI_PAYMENT_TRANSACTIONS = 'multi_payment_transactions';
export const PAYOUT_TRACKING_CACHE = 'payout_tracking_cache';
export const CONTRACT_SIGNING_URL_CACHE = 'contract_signing_url_cache';
export const PAYMENT_REQUESTS_CACHE = 'payment_requests_cache';
export const INCOMING_PAYMENT_REQUESTS_CACHE =
    'incoming_payment_requests_cache';
export const PAYMENT_REQUEST_PUBLIC_CACHE = 'payment_request_public_cache';
export const PAYMENT_REQUEST_CACHE = 'payment_request_cache';
export const ACCOUNTING_INTEGRATIONS_CACHE = 'accounting_integrations_cache';
export const ACCOUNTING_INTEGRATION_CACHE = 'accounting_integration_cache';
export const ACCOUNTING_INTEGRATION_VAT_CACHE =
    'accounting_integration_vat_cache';
export const ACCOUNTING_INTEGRATION_SETUP_CACHE =
    'accounting_integration_account_setup_cache';
export const ACCOUNTING_INTEGRATION_ACCOUNT_PLAN_CACHE =
    'accounting_integration_account_plan_cache';
export const ACCOUNTING_INTEGRATION_PAYMENTS_CACHE =
    'accounting_integration_payments_cache';
export const ACCOUNTING_INTEGRATION_INFO_CACHE =
    'accounting_integration_info_cache';
export const PAYOUT_ETA_CACHE = 'payout_eta_cache';
export const EMAIL_VERIFICATION_CACHE = 'email_verification_cache';
export const TAGS_CACHE = 'tags';
export const TAG_CACHE = 'tag';
export const AUTOMATION_INFINITE = 'automation_infinite';
export const AUTOMATION = 'automation';
export const CARDS_CACHE = 'cards';
export const CARD_CACHE = 'card';
export const CARD_HOLDER_CACHE = 'card_holder_cache';
export const CARD_HOLDERS_CACHE = 'card_holders_cache';
export const CARD_GROUPS = 'card_groups';
export const CARD_GROUP = 'card_group';
export const CARD_GROUP_BUDGETS = 'card_group_budgets';
export const MERCHANTS_CACHE = 'merchants_cache';
export const MERCHANT_DETAILS_CACHE = 'merchant_details_cache';
export const PAYOUT_PUBLIC_TRACKING_CACHE = 'payout_public_tracking_cache';
export const PAYOUT_PUBLIC_SWIFT_TRACKING_CACHE =
    'payout_public_swift_tracking_cache';
export const BUSINESS_ACTIVITIES = 'business_activities';
export const PAYMENT_TYPES = 'payment_types';
export const CARD_PURPOSE = 'card_purpose';
export const REQUIRED_DOCUMENTS = 'required_documents';
export const REQUIRED_INFORMATIONS = 'required_informations';
export const CONNECTED_CPRS = 'connected_crps';
export const EXPENSE_CATEGORIES = 'expense_categories';
export const EXPENSE_COST_CENTERS = 'expense_cost_centers';
export const EXPENSE_VATS = 'expense_vats';
export const EXPENSE_VATS_BATCH = 'expense_vats_batch';
export const CARD_TRANSACTION_PUBLIC_CACHE = 'card_transaction_public_cache';
export const CONRACTUAL_DOCUMENTS_CACHE = 'contractual_documents_cache';
export const EXPENSE_CONFIGURATION = 'expense_configuration_cache';
export const DEVICE_CONFIRMATION = 'device_confirmation';
