import {
    formatDateWithoutTZConversion,
    getLocalNowInZurich,
    getNextPossibleDay,
} from 'helpers/dates';
import React, {useEffect, useState} from 'react';
import {ButtonGroup} from 'react-bootstrap';
import classNames from 'classnames';
import {isBefore, isSameDay} from 'date-fns';
import {IPickerConfig} from '../DatePicker/HolidayDatePicker';

import styles from './DateToggle.module.scss';

export interface IDateToggleProps extends IPickerConfig {
    minDate: Date | null;
    value: string;
    onChange: (date: string) => void;
    filterDate: ((date: Date) => boolean) | undefined;
    excludeDates: Date[] | undefined;
}

export interface IDateToggleState {
    today: Date;
    plusOne: Date | undefined;
    plusTwo: Date | undefined;
}

const DateToggle = ({
    minDate,
    excludeDates,
    onChange,
    value,
    filterDate,
}: IDateToggleProps) => {
    const [state, setState] = useState<IDateToggleState>({
        today: new Date(),
        plusOne: undefined,
        plusTwo: undefined,
    });

    useEffect(() => {
        const setDays = () => {
            const today = getLocalNowInZurich();
            const plusOne = getNextPossibleDay(
                today,
                excludeDates || [],
                filterDate,
            );
            const plusTwo = getNextPossibleDay(
                plusOne,
                excludeDates || [],
                filterDate,
            );

            setState({today, plusOne, plusTwo});
        };

        setDays();
    }, [excludeDates, filterDate]);

    const handleClick = (day: keyof IDateToggleState) => {
        onChange(formatDateWithoutTZConversion(state[day]));
    };

    const todayIsDisabled =
        !!minDate &&
        (minDate.getDate() !== getLocalNowInZurich().getDate() ||
            (!isSameDay(minDate, getLocalNowInZurich()) &&
                isBefore(minDate, getLocalNowInZurich())));

    // if value isn't one of the options, set it to a valid one
    useEffect(() => {
        if (state.today && state.plusOne) {
            const todayValue = formatDateWithoutTZConversion(state.today);
            const plusOneValue = formatDateWithoutTZConversion(state.plusOne);
            const plusTwoValue = formatDateWithoutTZConversion(state.plusTwo);
            if (
                !todayIsDisabled &&
                value !== todayValue &&
                value !== plusOneValue &&
                value !== plusTwoValue
            ) {
                onChange(todayValue);
            } else if (
                todayIsDisabled &&
                value !== plusOneValue &&
                value !== plusTwoValue
            ) {
                onChange(plusOneValue);
            }
        }
    }, [onChange, state, todayIsDisabled, value]);

    const isActive = (day: keyof IDateToggleState) => {
        if (!state[day]) return false;
        return formatDateWithoutTZConversion(state[day]) === value;
    };

    return (
        <>
            <ButtonGroup className={styles.buttonGroup}>
                <button
                    type="button"
                    onClick={() => handleClick('today')}
                    disabled={!!todayIsDisabled}
                    className={classNames(styles.selectButton, {
                        active: isActive('today'),
                    })}
                >
                    Today
                </button>
                <button
                    type="button"
                    onClick={() => handleClick('plusOne')}
                    className={classNames(styles.selectButton, {
                        active: isActive('plusOne'),
                    })}
                >
                    +1 day
                </button>
                <button
                    type="button"
                    onClick={() => handleClick('plusTwo')}
                    className={classNames(styles.selectButton, {
                        active: isActive('plusTwo'),
                    })}
                >
                    +2 days
                </button>
            </ButtonGroup>
            <span className={styles.dateText}>
                <var data-var="date">{value}</var> (Europe/Zurich)
            </span>
        </>
    );
};

export default DateToggle;
