import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useClient} from 'context/auth-context';
import {CRP_BP_LIST_CACHE, IDENTIFICATION_LIST_CACHE} from 'constants/cache';
import {IdentificationChannel} from 'components/pages/OnboardingPage/steps/Identification';
import {ICrp} from 'api/business-partner';

const useIdentification = () => {
    const client = useClient();
    return useMutation<any, unknown, {channel: IdentificationChannel}>({
        mutationFn: ({channel}) =>
            client('/web_api/identifications', {
                data: {
                    channel,
                },
                method: 'POST',
            }),
    });
};

export interface IIdentification {
    '@id': string;
    '@type': 'Identification';
    id: number;
    externalId: string;
    crp: string;
    url: string;
    status: string;
    channel: string;
}

const useGetCurrentIdentification = (crp: ICrp | undefined) => {
    const client = useClient();
    const queryClient = useQueryClient();

    return useQuery<IIdentification>({
        queryKey: [IDENTIFICATION_LIST_CACHE, {crp: crp ? crp['@id'] : null}],
        queryFn: async () => {
            const response = await client<IIdentification[]>(
                `${crp?.['@id']}/identifications?current=1`,
                {},
            ).then(response => response[0] ?? null);

            if (response) {
                queryClient.invalidateQueries({queryKey: [CRP_BP_LIST_CACHE]});
            }

            return response;
        },
        enabled: !!crp,
        refetchInterval: 60000,
    });
};

export {useIdentification, useGetCurrentIdentification};
