const AccountBalanceWallet = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path fill="currentColor" d="M6.373 13.29h-4.22v1.343h4.22V13.29Z" />
        <path
            fill="currentColor"
            d="M21.846 20.057H3.559V7.92h13.054l1.238-1.128.957.967-1.576 1.557H4.966v9.346h15.473V5.233H8.792L7.554 6.415l-.956-.967 1.575-1.504h13.673v16.113Z"
        />
        <path
            fill="currentColor"
            d="M17.626 14.686h-4.22v1.343h4.22v-1.343Zm0-2.686h-4.22v1.344h4.22V12Z"
        />
    </svg>
);

export default AccountBalanceWallet;
