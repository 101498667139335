import React, {useMemo} from 'react';
import DatePicker from 'components/ui/DatePicker/DatePicker';
import {useGetHolidays} from 'api/holidays';
import {isWeekDay} from 'helpers/dates';

interface IProps {
    currencyFrom: string;
    currencyTo: string;
    value: string;
    className?: string;
    onChange(date: string): void;
}

const FilteredDatePicker: React.FC<IProps> = ({
    currencyFrom,
    currencyTo,
    value,
    className,
    onChange,
}) => {
    const {data: holidays} = useGetHolidays([currencyTo, currencyFrom]);

    const pickerProps = useMemo(
        () => ({
            value,
            onChange,
            filterDate: isWeekDay,
            minDate: new Date(),
            excludeDates:
                holidays && holidays.length
                    ? holidays.map((h: any) => new Date(h))
                    : [],
        }),
        [holidays, value, onChange],
    );

    return <DatePicker {...pickerProps} className={className} />;
};

export default React.memo(FilteredDatePicker);
