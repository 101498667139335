const Delete = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M7.308 20.5c-.499 0-.925-.176-1.278-.53a1.742 1.742 0 0 1-.53-1.277V6h-1V4.5H9v-.884h6V4.5h4.5V6h-1v12.693c0 .505-.175.932-.525 1.282-.35.35-.778.525-1.283.525H7.308ZM17 6H7v12.693a.3.3 0 0 0 .087.22.3.3 0 0 0 .22.087h9.385a.294.294 0 0 0 .212-.096.294.294 0 0 0 .096-.211V6ZM9.404 17h1.5V8h-1.5v9Zm3.692 0h1.5V8h-1.5v9Z"
        />
    </svg>
);

export default Delete;
