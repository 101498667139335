import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import {createRoot} from 'react-dom/client';
import AppProviders from 'context/index';
import * as Sentry from '@sentry/react';
import {ErrorBoundary} from 'react-error-boundary';
//@ts-ignore
import {BUILD_ENV, SENTRY_DSN} from 'config';
import {Helmet} from 'react-helmet';
import {isIframe} from 'helpers/common';
import ModalContainer from 'components/ui/ModalContainer/ModalContainer';
import App from './App';
import {ErrorPage} from './components/pages/ErrorPage/ErrorPage';
import './index.scss';

let errorHandler;

if (BUILD_ENV && BUILD_ENV !== 'local') {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: BUILD_ENV,
        denyUrls: [/https?:\/\/((cdn|www)\.)?(taboola|googletagmanager)\.com/],
        allowUrls: [
            /https?:\/\/(platform\.stage\.transfer4sme\.ch|app\.amnistreasury\.com)/,
        ],
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: BUILD_ENV === 'prod' ? 1.0 : 0,
        integrations: [new Sentry.Replay()],
    });
    errorHandler = (error: Error, info: {componentStack: string}) => {
        Sentry.withScope(scope => {
            scope.setExtras(info);
            Sentry.captureException(error);
        });
    };
} else {
    errorHandler = () => false;
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <>
        <Helmet>
            {isIframe() ? null : (
                <script>
                    {`
                    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/jk38lwyv';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
                `}
                </script>
            )}
        </Helmet>
        <AppProviders>
            <ErrorBoundary FallbackComponent={ErrorPage} onError={errorHandler}>
                <ModalContainer />
                <App />
            </ErrorBoundary>
        </AppProviders>
    </>,
);
