import axios, {
    AxiosError,
    AxiosHeaders,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios';
import * as Sentry from '@sentry/react';
import qs from 'qs';
//@ts-ignore
import {API_URL, HTTP_CLIENT_XDEBUG_QUERY_PARAMETER} from '../config';
import {parseJwt} from './common';
import {useTwoFAStore} from 'components/pages/TwoFAPage/useTwoFAStore';
import {
    RegisterListener,
    useEventSourceStore,
} from 'context/event-source-context';
import {IBase2FFAResponse} from 'api/futurae';

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response?.headers?.['cf-mitigated'] === 'challenge') {
            window.location.replace(`${API_URL}/web_api/challenge`);
        }
        throw error;
    },
);

axios.interceptors.response.use(
    async response => {
        if (!!response.data?.mfaClaimUUID) {
            return handleTwoFA(
                response,
                response.config as ClientRequestConfig,
            );
        }

        return response;
    },
    error => {
        return Promise.reject(error.response ? error.response.data : error);
    },
);

async function handleTwoFA(
    response: AxiosResponse,
    originalRequest?: ClientRequestConfig,
): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
        const {factor, mfaClaimUUID, factors, qrcode_url} = response.data;
        const {setVerifying, setResending, closeTwoFA} =
            useTwoFAStore.getState();

        const handleFailure = (error: any) => {
            console.error('2FA failed:', error);
            closeTwoFA();
            reject(error || '2FA verification failed');
        };

        if (factor === 'approve') {
            const {registerListener} = useEventSourceStore.getState();
            const unregister = registerListener(
                `mfa-${mfaClaimUUID}`,
                {
                    area: 'mfa_verification',
                    callback: event => {
                        if (event.status === 'success') {
                            resolve(event.payload as AxiosResponse);
                        } else {
                            handleFailure(event.message);
                        }
                        useTwoFAStore.getState().closeTwoFA();
                        unregister();
                    },
                },
                mfaClaimUUID,
            );
        }

        useTwoFAStore.getState().showTwoFA({
            factor,
            mfaClaimUUID,
            factors,
            qrcode_url,
            onSuccess: async ({passCode, token, user}) => {
                try {
                    if (factor === 'sms') {
                        setVerifying(true);
                        const verificationResponse =
                            await client<IBase2FFAResponse>(
                                '/web_api/futurae/verify_sms_code',
                                {
                                    data: {mfaClaimUUID, passCode, user},
                                    token,
                                },
                            );
                        setVerifying(false);
                        if (verificationResponse?.result === 'deny') {
                            return useTwoFAStore
                                .getState()
                                .setError(
                                    verificationResponse?.status_msg ||
                                        'Something went wrong',
                                );
                        }
                        useTwoFAStore.getState().closeTwoFA();
                        resolve(
                            verificationResponse as unknown as Promise<AxiosResponse>,
                        );
                    }
                } catch (error) {
                    setVerifying(false);
                    handleFailure(error);
                }
            },

            onResend: async () => {
                if (!originalRequest) return;
                try {
                    setResending(true);
                    const resendRequest = {
                        url: originalRequest.url!,
                        method: originalRequest.method || 'POST',
                        data: JSON.parse(originalRequest.data),
                        headers: originalRequest.headers,
                        token: originalRequest.token,
                    };

                    resolve(client(resendRequest.url, resendRequest));
                } catch (error) {
                    console.error('Failed to resend SMS:', error);
                } finally {
                    setResending(false);
                }
            },

            onFailure: handleFailure,
        });
    });
}

export interface ClientRequestConfig extends AxiosRequestConfig {
    token?: string;
    logout?: (errorMessage?: string) => void;
    keepOriginal?: boolean;
    onSuccess?: (verificationResponse: any) => void;
    registerListener?: RegisterListener;
}

interface ApiResponse<T = any> {
    'hydra:member'?: T;
    [key: string]: any;
}

async function client<T>(
    endpoint: string,
    {
        data,
        token,
        headers,
        method,
        keepOriginal,
        logout,
        onSuccess, // Pass callback
        registerListener,
        ...customConfig
    }: ClientRequestConfig = {},
    fullResponse = false,
    fullError = false,
): Promise<T> {
    const url = new URL(`${API_URL}${endpoint}`);

    if (HTTP_CLIENT_XDEBUG_QUERY_PARAMETER === '1') {
        url.searchParams.set('XDEBUG_SESSION', 'PHPSTORM');
    }

    if (window.__env__.MAINTENANCE_ACTIVE === '1') {
        if (logout) logout('Maintenance mode is active.');
        throw new Error('Service unavailable due to maintenance.');
    }

    const config: AxiosRequestConfig = {
        method: data || keepOriginal ? (method ?? 'POST') : 'GET',
        data: data ? (keepOriginal ? data : JSON.stringify(data)) : undefined,
        url: url.toString(),
        withCredentials: true,
        headers: new AxiosHeaders({
            ...(token ? {Authorization: `Bearer ${token}`} : {}),
            Accept: 'application/ld+json',
            ...(data ? {'Content-Type': 'application/ld+json'} : {}),
            ...headers,
        }),
        paramsSerializer: params =>
            qs.stringify(params, {
                encode: false,
                skipNulls: true,
                arrayFormat: 'brackets',
                filter: (_, value) => (value === '' ? undefined : value),
            }),
        ...customConfig,
    };

    if (token) {
        const tokenData = parseJwt(token);
        const tokenExpired = tokenData
            ? new Date().getTime() >= tokenData.exp * 1000
            : true;

        if (tokenExpired && logout) {
            logout('Session expired.');
            throw new Error('Session expired. Please log in again.');
        }
    }

    try {
        const response: AxiosResponse<ApiResponse<T>> = await axios(config);
        const responseData = response.data || response;

        if (fullResponse) {
            return responseData as T; // Safe cast because T is expected in this case
        }

        return 'hydra:member' in responseData
            ? (responseData['hydra:member'] as T)
            : (responseData as T); // Ensure the extracted data matches T
    } catch (error) {
        if (!(error instanceof AxiosError)) {
            console.error(error);
            return Promise.reject(error);
        }

        if (!error.response) {
            throw new Error('Network error. Please check your connection.');
        }

        const {status, data} = error.response;

        if (status === 401) {
            Sentry.captureException(error, scope => {
                scope.setContext('data', {
                    url: config.url,
                    method: config.method,
                    errorResponse: data,
                });
                return scope;
            });

            const errorMessage =
                (data as any)?.message === 'Invalid credentials.'
                    ? 'Invalid credentials.'
                    : 'Please re-authenticate.';

            if (logout) {
                logout(errorMessage);
            } else {
                throw new Error(errorMessage);
            }
        }

        if (status === 403) {
            Sentry.captureException(error, scope => {
                scope.setContext('data', {
                    url: config.url,
                    method: config.method,
                    payload: config.data
                        ? JSON.parse(config.data as string)
                        : null,
                });
                return scope;
            });
        }

        throw fullError
            ? error.response
            : new Error(data?.message ?? 'An unknown error occurred.');
    }
}

export {client};
