const Alert = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M11.25 14.039v-8.5h1.5v8.5h-1.5Zm0 4.423v-1.5h1.5v1.5h-1.5Z"
        />
    </svg>
);

export default Alert;
