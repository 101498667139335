const Integrations = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M19 20.75c-.624 0-1.18-.187-1.668-.562a2.619 2.619 0 0 1-.965-1.438H11a3.614 3.614 0 0 1-2.652-1.098A3.614 3.614 0 0 1 7.25 15c0-1.035.366-1.92 1.098-2.652A3.614 3.614 0 0 1 11 11.25h2c.619 0 1.149-.22 1.589-.661.44-.441.661-.971.661-1.59 0-.62-.22-1.15-.661-1.59A2.17 2.17 0 0 0 13 6.75H7.633a2.717 2.717 0 0 1-.973 1.438A2.632 2.632 0 0 1 5 8.75a2.653 2.653 0 0 1-1.948-.801 2.65 2.65 0 0 1-.802-1.947c0-.764.267-1.413.802-1.949A2.65 2.65 0 0 1 5 3.25a2.63 2.63 0 0 1 1.66.563c.483.375.807.854.973 1.437H13c1.036 0 1.92.366 2.652 1.098A3.614 3.614 0 0 1 16.75 9c0 1.036-.366 1.92-1.098 2.652A3.614 3.614 0 0 1 13 12.75h-2a2.16 2.16 0 0 0-1.589.662 2.17 2.17 0 0 0-.661 1.59c0 .62.22 1.15.661 1.589.44.44.97.66 1.589.66h5.367c.166-.584.49-1.063.973-1.438A2.633 2.633 0 0 1 19 15.25c.764 0 1.413.268 1.948.802A2.65 2.65 0 0 1 21.75 18c0 .764-.267 1.413-.802 1.948A2.65 2.65 0 0 1 19 20.75ZM5 7.25c.348 0 .643-.121.886-.364.242-.243.364-.538.364-.886 0-.347-.122-.642-.364-.885A1.205 1.205 0 0 0 5 4.75c-.348 0-.643.122-.886.365A1.205 1.205 0 0 0 3.75 6c0 .348.122.643.364.886.243.243.538.364.886.364Z"
        />
    </svg>
);

export default Integrations;
