import NewContactIcon from 'components/ui/Icon/svg/NewContact';
import NewGroup from 'components/ui/Icon/svg/NewGroup';
import SvgAmnis from 'components/ui/Icon/svg/Amnis';
import SvgAddFile from 'components/ui/Icon/svg/AddFile';
import SvgCopy from 'components/ui/Icon/svg/Copy';
import SvgEditPencil from 'components/ui/Icon/svg/EditPencil';
import Upload from 'components/ui/Icon/svg/Upload';
import Bell from 'components/ui/Icon/svg/Bell';
import InsightsIcon from 'components/ui/Icon/svg/Insights';
import StatementsIcon from 'components/ui/Icon/svg/Statements';
import SvgRegisterCheckmark from 'components/ui/Icon/svg/RegisterCheckmark';
import SvgRegisterCard from 'components/ui/Icon/svg/RegisterCard';
import SvgMagnifier from 'components/ui/Icon/svg/Magnifier';
import Bookmark from 'components/ui/Icon/svg/Bookmark';
import AttachFile from 'components/ui/Icon/svg/AttachFile';
import Invoice from 'components/ui/Icon/svg/Invoice';
import Visibility from 'components/ui/Icon/svg/Visibility';
import Pin from 'components/ui/Icon/svg/Pin';
import NewDebitCard from 'components/ui/Icon/svg/NewDebitCard';
import SuspendCards from 'components/ui/Icon/svg/SuspendCards';
import Download from 'components/ui/Icon/svg/Download';
import Filters from 'components/ui/Icon/svg/Filters';
import AccountBalanceWallet from 'components/ui/Icon/svg/AccountBalanceWallet';
import CurrencyExchange from 'components/ui/Icon/svg/CurrencyExchange';
import ArrowOutward from 'components/ui/Icon/svg/ArrowOutward';
import DebitCards from 'components/ui/Icon/svg/DebitCards';
import Contacts from 'components/ui/Icon/svg/Contacts';
import Tools from 'components/ui/Icon/svg/Tools';
import Automation from 'components/ui/Icon/svg/Automation';
import Home from 'components/ui/Icon/svg/Home';
import Campaign from 'components/ui/Icon/svg/Campaign';
import Balance from 'components/ui/Icon/svg/Balance';
import KeyboardArrowDown from 'components/ui/Icon/svg/KeyboardArrowDown';
import KeyboardArrowUp from 'components/ui/Icon/svg/KeyboardArrowUp';
import ChangePin from 'components/ui/Icon/svg/ChangePin';
import Check from 'components/ui/Icon/svg/Check';
import FilterList from 'components/ui/Icon/svg/FilterList';
import Calendar from 'components/ui/Icon/svg/Calendar';
import OpenInNew from 'components/ui/Icon/svg/OpenInNew';
import CheckmarkCircle from 'components/ui/Icon/svg/CheckmarkCircle';
import Merchant from 'components/ui/Icon/svg/Merchant';
import Chart from './svg/Chart';
import Document from './svg/Document';
import Envelope from './svg/Envelope';
import Exchange from './svg/Exchange';
import ExitArrow from './svg/ExitArrow';
import SvgRegistration from './svg/Registration';
import SvgMail from './svg/Mail';
import SvgLogin from './svg/Login';
import Flash from './svg/Flash';
import Gear from './svg/Gear';
import Globe from './svg/Globe';
import Question from './svg/Question';
import SvgLock from './svg/Lock';
import SvgSms from './svg/Sms';
import AverageFxChart from './svg/AverageFxChart';
import SvgChartValuations from './svg/ChartIconValuations';
import HedgeMonitorChart from './svg/HedgeMonitorChart';
import SvgSwap from './svg/Swap';
import SvgPaymentContact from './svg/PaymentContact';
import SvgCheckMark from './svg/CheckMark';
import SvgXMark from './svg/XMark';
import SvgInfo from './svg/Info';
import SvgWarning from './svg/Warning';
import SvgNewInfo from './svg/NewInfo';
import SvgUpArrow from './svg/UpArrow';
import SvgRegisterGlobe from './svg/RegisterGlobe';
import SvgRegisterXChange from './svg/RegisterXChange';
import SvgRegisterMoney from './svg/RegisterMoney';
import SvgDiamond from './svg/Diamond';
import SvgPay from './svg/Pay';
import SvgCollect from './svg/Collect';
import SvgUpgrade from './svg/Upgrade';
import SvgRegisterPeer from './svg/RegisterPeer';
import SvgArrowUpRight from './svg/ArrowUpRight';
import SvgArrowUpRightMulti from './svg/ArrowUpRightMulti';
import SvgPeerMulti from './svg/PeerMulti';
import SvgBriefcase from './svg/Briefcase';
import SvgPerson from './svg/Person';
import SvgRegisterCollect from './svg/RegisterCollect';
import SvgFaq from './svg/Faq';
import SvgCollateral from './svg/Collateral';
import SvgPlus from './svg/Plus';
import SvgFee from './svg/Fee';
import SvgChangeLayout from './svg/ChangeLayout';
import SvgTrash from './svg/Trash';
import SvgGroup from './svg/Group';
import SvgDownload from './svg/Download_old';
import SvgClose from './svg/Close';
import PaymentTypeFee from './svg/PaymentTypeFee';
import PaymentTypePeer from './svg/PaymentTypePeer';
import ProductTourIcon from './svg/ProductTour';
import ToolsNewIcon from './svg/ToolsNew';
import AccountsNew from './svg/AccountsNew';
import PaymentsNew from './svg/PaymentsNew';
import CurrencyExchangeOld from './svg/CurrencyExhange';
import TagsIcon from './svg/Tags';
import TagPlusIcon from './svg/TagPlus';
import Card from './svg/Card';
import Pause from './svg/Pause';
import Block from './svg/Block';
import Play from './svg/Play';
import Groups from './svg/Groups';
import Work from 'components/ui/Icon/svg/Work';
import Transactions from 'components/ui/Icon/svg/Transactions';
import Payments from 'components/ui/Icon/svg/Payments';
import Search from 'components/ui/Icon/svg/Search';
import Edit from 'components/ui/Icon/svg/Edit';
import PeerPayments from 'components/ui/Icon/svg/PeerPayment';
import ArrowForward from 'components/ui/Icon/svg/ArrowForward';
import Bolt from 'components/ui/Icon/svg/Bolt';
import ListView from 'components/ui/Icon/svg/ListView';
import SearchInsights from 'components/ui/Icon/svg/SearchInsights';
import ChevronLeft from 'components/ui/Icon/svg/ChevronLeft';
import BackArrow from './svg/BackArrow';
import Alert from 'components/ui/Icon/svg/Alert';
import AddUser from 'components/ui/Icon/svg/AddUser';
import Delete from 'components/ui/Icon/svg/Delete';
import Integrations from 'components/ui/Icon/svg/Integrations';
import Sync from 'components/ui/Icon/svg/Sync';
import DisableSync from 'components/ui/Icon/svg/DisableSync';
import Disconnect from 'components/ui/Icon/svg/Disconnect';
import TimeBased from './svg/TimeBased';
import BalanceBasedAutomationIcon from './svg/BalanceBasedAutomationIcon';
import MoreVertical from 'components/ui/Icon/svg/MoreVertical';
import Reminder from 'components/ui/Icon/svg/Reminder';
import Ebill from 'components/ui/Icon/svg/Ebill';

const ICONS = {
    chart: Chart,
    envelope: Envelope,
    exchange: Exchange,
    backArrow: BackArrow,
    exitArrow: ExitArrow,
    Login: SvgLogin,
    registration: SvgRegistration,
    mail: SvgMail,
    lock: SvgLock,
    sms: SvgSms,
    flash: Flash,
    gear: Gear,
    globe: Globe,
    question: Question,
    averageFxChart: AverageFxChart,
    valuationsChart: SvgChartValuations,
    hedgeMonitorChart: HedgeMonitorChart,
    swapIcon: SvgSwap,
    paymentContact: SvgPaymentContact,
    checkMark: SvgCheckMark,
    xMark: SvgXMark,
    info: SvgInfo,
    warning: SvgWarning,
    newInfo: SvgNewInfo,
    upArrow: SvgUpArrow,
    registerGlobe: SvgRegisterGlobe,
    registerXChange: SvgRegisterXChange,
    registerMoney: SvgRegisterMoney,
    registerPeer: SvgRegisterPeer,
    registerCheckmark: SvgRegisterCheckmark,
    registerCard: SvgRegisterCard,
    diamond: SvgDiamond,
    pay: SvgPay,
    collect: SvgCollect,
    upgrade: SvgUpgrade,
    arrowUpRight: SvgArrowUpRight,
    arrowUpRightMulti: SvgArrowUpRightMulti,
    peerMulti: SvgPeerMulti,
    briefcase: SvgBriefcase,
    person: SvgPerson,
    registerCollect: SvgRegisterCollect,
    faq: SvgFaq,
    collateral: SvgCollateral,
    plus: SvgPlus,
    fee: SvgFee,
    changeLayout: SvgChangeLayout,
    trash: SvgTrash,
    group: SvgGroup,
    download_old: SvgDownload,
    close: SvgClose,
    paymentTypeFee: PaymentTypeFee,
    paymentTypePeer: PaymentTypePeer,
    newContact: NewContactIcon,
    newGroup: NewGroup,
    amnis: SvgAmnis,
    addFile: SvgAddFile,
    copy: SvgCopy,
    editPencil: SvgEditPencil,
    currencyExchange_old: CurrencyExchangeOld,
    productTour: ProductTourIcon,
    accountsNew: AccountsNew,
    paymentsNew: PaymentsNew,
    toolsNew: ToolsNewIcon,
    upload: Upload,
    bell: Bell,
    tagPlus: TagPlusIcon,
    tags: TagsIcon,
    insights: InsightsIcon,
    statements: StatementsIcon,
    timeBased: TimeBased,
    balanceBasedAutomation: BalanceBasedAutomationIcon,
    card: Card,
    block: Block,
    pause: Pause,
    play: Play,
    groups: Groups,
    magnifier: SvgMagnifier,
    bookmark: Bookmark,
    attachFiles: AttachFile,
    invoice: Invoice,
    visibility: Visibility,
    pin: Pin,
    newDebitCard: NewDebitCard,
    suspendCards: SuspendCards,
    download: Download,
    filters: Filters,
    accountBalanceWallet: AccountBalanceWallet,
    currencyExchange: CurrencyExchange,
    arrowOutward: ArrowOutward,
    debitCards: DebitCards,
    contacts: Contacts,
    tools: Tools,
    automation: Automation,
    home: Home,
    campaign: Campaign,
    balance: Balance,
    keyboardArrowDown: KeyboardArrowDown,
    keyboardArrowUp: KeyboardArrowUp,
    changePin: ChangePin,
    check: Check,
    filterList: FilterList,
    calendar: Calendar,
    openInNew: OpenInNew,
    checkmarkCircle: CheckmarkCircle,
    merchant: Merchant,
    work: Work,
    transactions: Transactions,
    document: Document,
    payments: Payments,
    search: Search,
    edit: Edit,
    peerPayment: PeerPayments,
    arrowForward: ArrowForward,
    bolt: Bolt,
    listView: ListView,
    searchInsights: SearchInsights,
    chevronLeft: ChevronLeft,
    alert: Alert,
    addUser: AddUser,
    delete: Delete,
    integrations: Integrations,
    sync: Sync,
    disableSync: DisableSync,
    disconnect: Disconnect,
    moreVertical: MoreVertical,
    reminder: Reminder,
    ebill: Ebill,
} as const;
export type Icons = keyof typeof ICONS;

export default ICONS;
