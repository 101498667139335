const Ebill = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M11.594 14.75v-.895a2.121 2.121 0 0 1-.954-.48c-.267-.236-.463-.569-.588-1l.77-.312c.105.333.26.587.464.76.205.174.474.26.808.26.284 0 .526-.063.724-.192.197-.128.296-.328.296-.599 0-.243-.076-.436-.229-.578-.152-.142-.507-.304-1.062-.484-.597-.188-1.007-.412-1.23-.672a1.418 1.418 0 0 1-.333-.953c0-.452.146-.802.438-1.052.291-.25.59-.393.896-.428V7.25h.833v.875c.347.056.634.183.86.38.225.199.39.44.494.725l-.77.333a1.054 1.054 0 0 0-.355-.5c-.153-.111-.36-.167-.625-.167-.305 0-.538.068-.698.203a.636.636 0 0 0-.24.506c0 .229.105.41.313.541.208.132.57.271 1.083.417.48.139.842.36 1.089.661.247.303.37.651.37 1.047 0 .493-.146.868-.438 1.125a2.04 2.04 0 0 1-1.083.48v.874h-.833Z"
        />
        <path
            fill="currentColor"
            d="M7.308 22.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.282V3.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h9.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v17.385c0 .505-.175.932-.525 1.282-.35.35-.778.525-1.283.525H7.308ZM7 17.77v2.923c0 .077.032.147.096.211a.294.294 0 0 0 .212.096h9.384a.294.294 0 0 0 .212-.096.294.294 0 0 0 .096-.211V17.77H7Zm5 2.5a.853.853 0 0 0 .626-.259.853.853 0 0 0 .259-.626.852.852 0 0 0-.26-.626A.852.852 0 0 0 12 18.5a.853.853 0 0 0-.626.26.852.852 0 0 0-.259.625c0 .245.087.453.259.626a.853.853 0 0 0 .626.259Zm-5-4h10V5.75H7v10.52ZM7 4.25h10v-.942a.294.294 0 0 0-.096-.211.294.294 0 0 0-.212-.097H7.308a.294.294 0 0 0-.212.097.294.294 0 0 0-.096.211v.942Z"
        />
    </svg>
);

export default Ebill;
