import React, {useRef, useState} from 'react';
import FA from 'react-fontawesome';

import useModal from 'components/ui/ModalContainer/useModal';
import Alert from 'components/ui/Alert/Alert';
import Button, {IButtonProps} from 'components/ui/Button';

import styles from './ConfirmationOverlay.module.scss';
import AmnisButton, {
    IAmnisButtonProps,
} from 'components/ui/AmnisButton/AmnisButton';

export type ConfirmationOverlaySubmit = (
    event: React.MouseEvent<HTMLButtonElement>,
    setError?: (error: string) => void,
) => void;

export interface IConfirmationOverlay {
    onConfirm: ConfirmationOverlaySubmit;
    onCancel?: () => void;
    onClose?: () => void;
    title: React.ReactNode;
    description: React.ReactNode;
    isLoading?: boolean;
    confirmLabel?: string;
    cancelLabel?: string;
    confirmVariant?: IAmnisButtonProps['variant'];
    cancelVariant?: IAmnisButtonProps['variant'];
    hideClose?: boolean;
}

const ConfirmationOverlay = ({
    onConfirm,
    onCancel,
    title,
    description,
    isLoading,
    confirmLabel = 'Ok',
    cancelLabel = 'Cancel',
    confirmVariant = 'default',
    cancelVariant = 'default',
    onClose,
    hideClose,
}: IConfirmationOverlay) => {
    const {closeModal} = useModal();
    const [error, setError] = useState<string>('');
    const containerRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick: React.MouseEventHandler = event => {
        if (event.target === containerRef?.current) {
            closeModal('confirmation');
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
        <div
            onClick={handleOutsideClick}
            ref={containerRef}
            className={styles.overlayContainer}
        >
            <div className={styles.modal}>
                {hideClose ? null : (
                    <button
                        className={styles.closeButton}
                        onClick={() => {
                            if (onClose) {
                                onClose();
                            }
                            closeModal('confirmation');
                        }}
                    >
                        <FA size="lg" name="times" />
                    </button>
                )}
                <h2>{title}</h2>
                <div>{description}</div>
                {error ? (
                    <div>
                        <Alert message={error} className={styles.errorAlert} />
                    </div>
                ) : null}
                <div className={styles.buttonRow}>
                    {onCancel ? (
                        <AmnisButton
                            isLoading={isLoading}
                            intent="secondary"
                            onClick={onCancel}
                            variant={cancelVariant}
                        >
                            {cancelLabel}
                        </AmnisButton>
                    ) : null}
                    <AmnisButton
                        isLoading={isLoading}
                        intent="primary"
                        onClick={event => onConfirm(event, setError)}
                        disabled={!!error}
                        variant={confirmVariant}
                    >
                        {confirmLabel}
                    </AmnisButton>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationOverlay;
