import {useConfirmMobileRegistration, useEnrollRegistration} from 'api/futurae';
import {useUpdateUser} from 'api/users';
import {useTwoFAStore} from 'components/pages/TwoFAPage/useTwoFAStore';
import {FormOnSubmit} from 'components/ui/Form/Form/Form';
import TwoFAPhoneEdit, {
    ITwoFAPhoneEditFormValues,
} from 'components/ui/TwoFAPhoneEdit/TwoFAPhoneEdit';
import {useAuth} from 'context/auth-context';
import {handleTypedFormError} from 'helpers/handleFormError';
import {useEffect, useState} from 'react';

interface IProps {
    onSuccess?: () => void;
}

const EnrollMobile = ({onSuccess}: IProps) => {
    const {user, token} = useAuth();
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const {mutate: updateUser, isPending: isUpdateUserLoading} =
        useUpdateUser();
    const {
        mutate: enrollNewUser,
        isPending: isLoadingEnroll,
        error,
    } = useEnrollRegistration();
    const showTwoFA = useTwoFAStore(state => state.showTwoFA);
    const setVerifying = useTwoFAStore(state => state.setVerifying);
    const closeTwoFA = useTwoFAStore(state => state.closeTwoFA);
    const setError = useTwoFAStore(state => state.setError);

    const {
        mutate: confirmMobileRegistration,
        isPending: isLoadingRegistration,
    } = useConfirmMobileRegistration();

    useEffect(() => {
        if (user) {
            setPhoneNumber(user.phone);
        }
    }, [user]);
    const handlePhoneEditSubmit: FormOnSubmit<ITwoFAPhoneEditFormValues> = (
        values,
        setPhoneError,
    ) => {
        const {phone} = values;
        if (phone && phone !== user.phone) {
            setPhoneNumber(phone);
            updateUser(
                {'@id': user['@id'], phone},
                {
                    onSuccess: () => handleEnrollNewUser(),
                    onError: (response: any) => {
                        handleTypedFormError(response, setPhoneError, values);
                    },
                },
            );
        } else {
            setPhoneNumber(user.phone);
            handleEnrollNewUser();
        }
    };

    const handleEnrollNewUser = () => {
        enrollNewUser(
            {},
            {
                onSuccess: response => {
                    showTwoFA({
                        isOpen: true,
                        factor: 'sms',
                        onResend: () => {
                            handleEnrollNewUser();
                        },
                        isVerifying: isLoadingRegistration,
                        onSuccess: ({passCode}) => {
                            setVerifying(true);
                            confirmMobileRegistration(
                                {
                                    passCode,
                                    token,
                                    deviceId: response.deviceId,
                                    futuraeId: response['@id']
                                        .split('/')
                                        .pop()!,
                                },
                                {
                                    onSuccess: () => {
                                        setVerifying(false);
                                        closeTwoFA();
                                        onSuccess?.();
                                    },
                                    onError: (error: any) => {
                                        setVerifying(false);
                                        console.log({error});
                                        setError(
                                            error?.['hydra:description'] ||
                                                'Something went wrong',
                                        );
                                    },
                                },
                            );
                        },
                    });
                },
                onError: response => {
                    console.log(response);
                },
            },
        );
    };

    return (
        <TwoFAPhoneEdit
            phone={phoneNumber}
            handleFormSubmit={handlePhoneEditSubmit}
            isLoading={isUpdateUserLoading || isLoadingEnroll}
            error={error}
        />
    );
};

export default EnrollMobile;
