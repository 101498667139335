import styles from './ConfirmationModal.module.scss';
import AmnisButton from 'components/ui/AmnisButton/AmnisButton';

interface IProps {
    title: string;
    description: React.ReactNode;
    translateDescription?: boolean;
    isLoading?: boolean;
    onSubmit: () => void;
    onClose?: () => void;
}

const ConfirmationModal = ({
    title,
    description,
    translateDescription = true,
    onClose,
    onSubmit,
    isLoading = false,
}: IProps) => (
    <div className={styles.container}>
        <div className={styles.header}>
            <h5>{title}</h5>
        </div>
        <div
            translate={translateDescription ? 'yes' : 'no'}
            className={styles.body}
        >
            {description}
        </div>
        <div className={styles.footer}>
            {onClose ? (
                <AmnisButton size="xsmall" variant="tools" onClick={onClose}>
                    Cancel
                </AmnisButton>
            ) : null}
            <AmnisButton size="xsmall" isLoading={isLoading} onClick={onSubmit}>
                Confirm
            </AmnisButton>
        </div>
    </div>
);

export default ConfirmationModal;
