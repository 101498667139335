import React, {
    useState,
    useCallback,
    useMemo,
    useEffect,
    useContext,
} from 'react';
import * as Sentry from '@sentry/react';
import {useGetUser, User} from 'api/users';
import Loader from 'components/ui/AmnisLoader/AmnisLoader';
import {client, ClientRequestConfig} from 'helpers/api-client';
import {
    getItemFromStorage,
    removeItemFromStorage,
    setItemToStorage,
} from 'helpers/localStorage';
import {LOCAL_STORAGE_AUTH_KEY, PATH} from 'constants/common';
import {useHistory} from 'react-router';
import {BusinessPartnerContext} from 'context/business-partner-context';
import {useResolveInviteStore} from 'components/pages/ResolveInvitePage/resolveInviteStore';
import {useLanguage} from './language-context';
import {useQueryClient} from '@tanstack/react-query';
import {useEventSourceStore} from 'context/event-source-context';
import useModal from 'components/ui/ModalContainer/useModal';
import {LAST_SEEN_KEY} from 'components/ui/IdleTimer/IdleTimer';

const AuthContext = React.createContext<
    | {
          user: User;
          token: string;
          loginScreenErrorMessage: string;
          logout: (errorMessage?: string) => void;
          login: (token?: string, redirect?: string) => void;
          setToken: (token: string | null) => void;
      }
    | undefined
>(undefined);
AuthContext.displayName = 'AuthContext';

const AuthProvider = (props: any) => {
    const reset = useResolveInviteStore(state => state.reset);
    const queryClient = useQueryClient();
    const history = useHistory();
    const {updateLanguageById} = useLanguage();
    const [token, setToken] = useState(
        getItemFromStorage(LOCAL_STORAGE_AUTH_KEY),
    );
    const resetEventSrouce = useEventSourceStore(state => state.reset);
    const [loginScreenErrorMessage, setLoginScreenErrorMessage] = useState<
        string | undefined
    >('');
    const hash = useResolveInviteStore(state => state.hash);
    const type = useResolveInviteStore(state => state.type);
    const {closeModal} = useModal();

    const onSuccess = useCallback(
        (data: User) => {
            if (data.language) {
                updateLanguageById(data.language);
            }

            if (hash) {
                const path =
                    type === 'business-partner'
                        ? `/business-partner-invite/${hash}`
                        : `/contact-invite/${hash}`;

                history.push(path);
            }
        },
        [hash, history, type, updateLanguageById],
    );

    const logout = useCallback((errorMessage?: string) => {
        removeItemFromStorage(LOCAL_STORAGE_AUTH_KEY);
        removeItemFromStorage(LAST_SEEN_KEY);
        reset();
        setLoginScreenErrorMessage(errorMessage);
        setToken(null);
        resetEventSrouce();
        closeModal();
    }, []);

    const {
        data: user,
        isLoading,
        isSuccess,
        isFetched,
        status,
    } = useGetUser(token, logout);

    useEffect(() => {
        if (!user && !token) {
            queryClient.clear();
        }
    }, [user, token]);

    useEffect(() => {
        if (user) onSuccess(user);
    }, [onSuccess, user]);

    const login = useCallback(
        (data: any, redirect?: string) => {
            if (!data || typeof data !== 'string') {
                Sentry.captureException(new Error(`Invalid token: ${data}`));
                setLoginScreenErrorMessage(
                    'There was an error during login, please try again or get in touch with us. Error code I-004.',
                );
                history.push(PATH.LOGIN);
                return;
            }
            setLoginScreenErrorMessage('');
            setItemToStorage(LOCAL_STORAGE_AUTH_KEY, data);
            setToken(data);

            if (redirect) {
                return history.push(redirect);
            }

            const params = new URLSearchParams(history.location.search);

            if (params.get('afterLoginRedirect')) {
                return history.push(
                    window.atob(params.get('afterLoginRedirect')!),
                );
            }

            history.push(PATH.DASHBOARD);
        },
        [history],
    );

    const values = useMemo(
        () => ({
            user,
            token,
            loginScreenErrorMessage,
            logout,
            login,
            setToken,
        }),
        [user, token, loginScreenErrorMessage, logout, login, setToken],
    );

    if (!user && isLoading) {
        return <Loader big />;
    }

    if (isSuccess || isFetched || status === 'pending') {
        if (user) onSuccess(user);
        return <AuthContext.Provider value={values} {...props} />;
    }

    if (logout) {
        logout();
    }
};

const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`);
    }
    return context;
};

const useClient = () => {
    const auth = useAuth();
    const businessPartner = useContext(BusinessPartnerContext);

    return useCallback(
        <T extends any>(
            endpoint: string,
            config?: ClientRequestConfig,
            fullResponse?: boolean,
            fullError?: boolean,
        ) => {
            const headers: Record<string, string> = Object.fromEntries(
                Object.entries(config?.headers ?? {}).flatMap(([key, value]) =>
                    value !== undefined ? [[key, String(value)]] : [],
                ),
            );

            if (businessPartner?.activeBusinessPartner) {
                headers['Business-Partner'] =
                    '' + businessPartner.activeBusinessPartner.id;
            }

            return client<T>(
                endpoint,
                {
                    ...config,
                    token: auth?.token || config?.token,
                    logout: auth?.logout,
                    headers,
                },
                fullResponse,
                fullError,
            );
        },
        [auth, businessPartner],
    );
};

export {AuthProvider, useAuth, useClient};
