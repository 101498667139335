import {useGetMercureToken} from 'api/notifications';
import {EventSourcePolyfill, MessageEvent} from 'event-source-polyfill';
import {parseJwt} from 'helpers/common';
import {useAuth} from './auth-context';
import {create} from 'zustand';
import {useEffect} from 'react';

export interface IMessage {
    area: string;
    status: 'success' | 'error';
    message: string;
    payload: Record<string, any>;
    mfaClaimUUID: string;
}

export type ListenerHandler = {
    area: string;
    callback: (data: IMessage) => void;
};

export type RegisterListener = (
    listenerKey: string,
    listenerHandler: ListenerHandler,
    mfaClaimUUID?: string,
) => () => void;

export type RegisterAllListener = (
    listenerKey: string,
    callback: (event: MessageEvent) => void,
) => () => void;

export const allListeners = 'allListeners';

interface EventSourceState {
    eventSource: EventSourcePolyfill | null;
    mercureToken: string | null;
    listenerMap: Map<string, (event: MessageEvent) => void>;
    setMercureToken: (token: string | null) => void;
    registerListener: RegisterListener;
    removeListener: (listenerKey: string) => void;
    removeAllListeners: () => void;
    registerNotifications: RegisterAllListener;
    initializeEventSource: (token: string | null) => void;
    reset: () => void;
}

export const useEventSourceStore = create<EventSourceState>((set, get) => ({
    eventSource: null,
    mercureToken: null,
    listenerMap: new Map(),
    setMercureToken: token => set({mercureToken: token}),

    initializeEventSource: token => {
        const {mercureToken, eventSource} = get();
        if (!token || !mercureToken || eventSource) return;

        const parsedToken = parseJwt(token);
        if (!parsedToken || !import.meta.env.VITE_MERCURE_URL) return;

        const url = new URL(import.meta.env.VITE_MERCURE_URL);
        url.searchParams.set('topic', `/users/${parsedToken.id}`);

        const newEventSource = new EventSourcePolyfill(url.toString(), {
            lastEventIdQueryParameterName: 'Last-Event-ID',
            headers: {Authorization: `Bearer ${mercureToken}`},
        });

        if (import.meta.env.NODE_ENV === 'development') {
            newEventSource.addEventListener('message', event => {
                console.log(JSON.parse(event.data));
            });
        }

        set({eventSource: newEventSource});
    },

    registerListener: (listenerKey, listenerHandler, mfaClaimUUID) => {
        const listener = (event: MessageEvent) => {
            const parsed = JSON.parse(event.data) as IMessage;
            if (
                parsed.area === listenerHandler.area ||
                parsed.mfaClaimUUID === mfaClaimUUID
            ) {
                listenerHandler.callback(parsed);
            }
        };
        const {listenerMap, eventSource} = get();
        listenerMap.set(listenerKey, listener);
        eventSource?.addEventListener('message', listener);

        return () => get().removeListener(listenerKey);
    },

    removeListener: listenerKey => {
        const {listenerMap, eventSource} = get();
        const listener = listenerMap.get(listenerKey);
        if (listener) {
            eventSource?.removeEventListener('message', listener);
            listenerMap.delete(listenerKey);
        }
    },

    removeAllListeners: () => {
        for (let key in get().listenerMap) {
            get().removeListener(key);
        }
    },

    registerNotifications: (listenerKey, callback) => {
        const listener = (event: MessageEvent) => callback(event);
        const {listenerMap, eventSource} = get();
        listenerMap.set(listenerKey, listener);
        eventSource?.addEventListener('message', listener);

        return () => get().removeListener(listenerKey);
    },

    reset: () => {
        get().removeAllListeners();
        set({
            eventSource: null,
            listenerMap: new Map(),
            mercureToken: null,
        });
    },
}));

export const useEventSource = () => {
    const {token} = useAuth();
    const {mercureToken, setMercureToken, initializeEventSource} =
        useEventSourceStore();
    const {mutate: getMercureToken} = useGetMercureToken();

    useEffect(() => {
        if (token) {
            getMercureToken(token, {
                onSuccess: response => setMercureToken(response.token),
            });
        }
    }, [token, getMercureToken, setMercureToken]);

    useEffect(() => {
        initializeEventSource(token);
    }, [token, mercureToken, initializeEventSource]);
};
