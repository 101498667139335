import {useTwoFAStore} from 'components/pages/TwoFAPage/useTwoFAStore';
import ModalOverlay from 'components/ui/Overlays/ModalOverlay/ModalOverlay';
import styles from './TwoFAModal.module.scss';
import ConfirmationMobile from 'components/ui/ConfirmationMobile/ConfirmationMobile';
import FuturaeAuth from 'components/ui/FuturaeAuth/FuturaeAuth';

const TwoFAModal = () => {
    const {
        isOpen,
        factor,
        qrcode_url,
        onSuccess,
        closeTwoFA,
        isVerifying,
        isResending,
        error,
        onResend,
        setError,
        onFailure,
    } = useTwoFAStore();

    const handleClose = () => {
        onFailure?.('Declined');
    };

    if (!isOpen) return null;

    return (
        <div className={styles.container}>
            <ModalOverlay onClose={handleClose} doNotCloseOnOutsideClick>
                {factor === 'sms' ? (
                    <ConfirmationMobile
                        isVerifying={isVerifying}
                        isResending={isResending}
                        onResend={onResend!}
                        onSuccess={onSuccess!}
                        error={error}
                        setError={setError}
                    />
                ) : null}
                {factor === 'approve' ? (
                    <FuturaeAuth
                        futuraImgUrl={qrcode_url!}
                        onSuccessful={closeTwoFA}
                    />
                ) : null}
            </ModalOverlay>
        </div>
    );
};

export default TwoFAModal;
