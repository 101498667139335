import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {client} from 'helpers/api-client';
import {useClient, useAuth} from 'context/auth-context';
import {
    CARD_CACHE,
    CARDS_CACHE,
    GET_AUTH_FACTORS,
    GET_FUTURAE_INFO,
    GET_QR_AUTH_STATUS,
    USER_CACHE,
} from 'constants/cache';
import {ICard, Permission} from 'types/api';
import {CardType} from 'types/cardType';
import {CardStatus} from 'types/cardStatus';
import {useNotification} from 'context/notification-context';
import {PATCH_HEADERS} from 'api/constants';

export type AuthFactors = 'approve' | 'sms';
export type AuthTypes =
    | 'login'
    | 'reset_password'
    | 'payout_transaction_detail'
    | 'get_pin'
    | 'secure_info'
    | 'onboarding'
    | 'update-permission';

interface IBaseAuth extends Partial<Permission> {
    type: AuthTypes;
    factor?: AuthFactors;
    passcode?: string;
    token?: string;
    '@id'?: string;
}

interface ILoginAuth {
    token: string;
}

interface ICardAuthNew {
    cardId: number;
}

export interface IUseAuthenticateFuturaeResponse {
    area: string;
    message: string;
    factor: AuthFactors;
    mfaClaimUUID: string;
    payload: Record<string, any>;
    status: string;
    qrcode_url: string;
    token?: string;
}

export type IBase2FFAResponse = {
    passcode_type?: AuthTypes;
    result: 'allow' | 'deny';
    status: 'allow'; // TODO
    status_msg: string;
};

type ICardSecureInfoResponse = IBase2FFAResponse & {
    id: number;
    cardToken: string;
    type: CardType;
    shippingStatus: string;
    sequenceNumber: number;
    embossedName: string;
    panFirst6: string;
    panLast4: string;
    expiry: string;
    pinFailCount: number;
    pan: string;
    cvv2: string;
    cardProfile: string;
    cardStatus: CardStatus;
    createdAt: string;
    updatedAt: string;
    cardholderId: number;
    primaryCardholder: {
        firstName: string;
        lastName: string;
        embossedName: string;
    };
    attributes: {
        companyName: string;
        bulkRecipient: {
            shippingAddress: [];
        };
    };
    error?: string;
};

type ICardPinResponse = IBase2FFAResponse & {
    pin?: string;
    error?: string;
};

const BASE_FUTURAE_URL = `/web_api/futurae/auth`;

const useGetCardSecureInfo = () => {
    const authenticatedClient = useClient();

    return useMutation<ICardSecureInfoResponse, unknown, ICardAuthNew>({
        mutationFn: data =>
            authenticatedClient(
                `${BASE_FUTURAE_URL}/card/secure_info/${data.cardId}`,
                {
                    data,
                    method: 'POST',
                },
            ),
    });
};

const useGetCardPin = () => {
    const authenticatedClient = useClient();

    return useMutation<ICardPinResponse, unknown, ICardAuthNew>({
        mutationFn: data =>
            authenticatedClient(
                `${BASE_FUTURAE_URL}/card/get_pin/${data.cardId}`,
                {
                    data,
                    method: 'POST',
                },
            ),
    });
};

const useGetTwoFALogin = () => {
    const authenticatedClient = useClient();
    const {addNotification} = useNotification();

    return useMutation<IUseAuthenticateFuturaeResponse, unknown, ILoginAuth>({
        mutationFn: ({token}) =>
            authenticatedClient(`${BASE_FUTURAE_URL}/login`, {
                method: 'POST',
                data: {},
                token,
            }),
        onError: (error: any) => {
            const message =
                typeof error === 'string'
                    ? error
                    : error['hydra:description'] || 'Something went wrong';
            addNotification({area: 'login', message, status: 'error'}, 'login');
        },
    });
};

const useGetOnboardingTwoFA = () => {
    const authenticatedClient = useClient();

    return useMutation<IUseAuthenticateFuturaeResponse, unknown, any>({
        mutationFn: () =>
            authenticatedClient(`${BASE_FUTURAE_URL}/onboarding`, {
                method: 'POST',
                data: {},
            }),
    });
};

const useGetPasswordResetTwoFA = () => {
    const authenticatedClient = useClient();

    return useMutation<
        IUseAuthenticateFuturaeResponse,
        unknown,
        {token: string}
    >({
        mutationFn: ({token}) =>
            authenticatedClient(`${BASE_FUTURAE_URL}/reset_password`, {
                method: 'POST',
                data: {},
                token,
            }),
    });
};
const useConfirmSMS = () => {
    return useMutation<
        any,
        unknown,
        {
            user: string;
            token: string;
            passCode: string;
            mfaClaimUUID: string | null;
        }
    >({
        mutationFn: ({user, token, passCode, mfaClaimUUID}) => {
            return client('/web_api/futurae/verify_sms_code', {
                data: {user, passCode, mfaClaimUUID},
                method: 'POST',
                token,
            });
        },
    });
};

const useRetrigger2FAForCardOrder = () => {
    const authenticatedClient = useClient();
    const queryClient = useQueryClient();

    return useMutation<IUseAuthenticateFuturaeResponse, unknown, ICard>({
        mutationFn: ({'@id': iri}) =>
            authenticatedClient(`${iri}/2fa_re_trigger`, {
                data: {},
                method: 'PATCH',
                ...PATCH_HEADERS,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [CARDS_CACHE]});
            queryClient.invalidateQueries({queryKey: [CARD_CACHE]});
        },
    });
};

interface IGetAuthFactors {
    '@id': string;
    id: string;
    factors: ('sms' | 'approve')[];
    active: boolean;
}

const useGetAuthFactors = (token?: string) => {
    return useQuery<IGetAuthFactors>({
        queryKey: [GET_AUTH_FACTORS, {token}],
        queryFn: () =>
            client('/web_api/futurae/get_auth_factors', {
                token,
            }),
        enabled: !!token,
    });
};

export interface ISmsDevice {
    factor: 'sms';
    deviceId: string;
    number: string;
}

export interface IApproveDevice {
    factor: 'approve';
    deviceId: string;
    type: string;
}

export type Device = ISmsDevice | IApproveDevice;

export interface IGetFuturaeInfo {
    '@context': '/web_api/contexts/Futurae';
    '@id': string;
    '@type': 'Futurae';
    createdAt: string;
    devices: Partial<Record<Device['factor'], Device>>;
    factor: AuthFactors;
    futuraeUserId: string;
    updatedAt: string;
}

const useGetFuturaeInfo = (authFactors?: IGetAuthFactors) => {
    const authenticatedClient = useClient();

    return useQuery<IGetFuturaeInfo>({
        queryKey: [GET_FUTURAE_INFO, authFactors],
        queryFn: () =>
            authenticatedClient(
                `/web_api/futurae/get_info/${authFactors?.id}`,
                {},
            ),
        enabled: !!authFactors,
    });
};

const useGetQRAuthStatus = (enabled: boolean, token: string) => {
    return useQuery<any>({
        queryKey: [GET_QR_AUTH_STATUS, {token}],
        queryFn: () => client('/web_api/futurae/auth_status', {token}),
        enabled,
    });
};
const useAddQRCode = () => {
    const authenticatedClient = useClient();
    const {user} = useAuth();

    return useMutation<any, unknown, null>({
        mutationFn: (_: null) =>
            authenticatedClient('/web_api/futurae/enroll_device', {
                data: {user: user['@id']},
                method: 'POST',
            }),
    });
};

interface IEnrollNewUserResponse {
    '@context': '/contexts/Futurae';
    '@id': string;
    '@type': 'Futurae';
    user: string;
    smsStatus: 'sent';
    deviceId: string;
    deviceName: string;
}

export interface IEnrollNewUserError {
    '@context': '/contexts/Error';
    '@id': string;
    '@type': 'hydra:Error';
    title: string;
    detail: string;
    status: number;
    type: string;
    'hydra:title': string;
    'hydra:description': string;
}

const useEnrollRegistration = () => {
    const authenticatedClient = useClient();
    return useMutation<IEnrollNewUserResponse, IEnrollNewUserError, any>({
        mutationFn: () =>
            authenticatedClient(
                '/web_api/futurae/enroll_and_activate_by_phone',
                {
                    data: {},
                    method: 'POST',
                },
            ),
    });
};

const useEnrollOnResetPassword = () => {
    return useMutation({
        mutationFn: ({token}: {token: string}) =>
            client('/web_api/futurae/enroll_and_activate_by_phone', {
                data: {userConfirmationToken: token},
                method: 'POST',
            }),
    });
};

const useConfirmMobileRegistration = () => {
    const queryClient = useQueryClient();

    return useMutation<
        any,
        unknown,
        {
            passCode: string;
            futuraeId: string;
            token: string;
            deviceId: string;
        }
    >({
        mutationFn: ({passCode, futuraeId, token, deviceId}) => {
            return client(`/web_api/futurae/verify_enroll_sms/${futuraeId}`, {
                data: {passCode, deviceId},
                token,
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                },
            });
        },
        onSuccess: response => {
            queryClient.invalidateQueries({queryKey: [USER_CACHE]});
            queryClient.invalidateQueries({queryKey: [GET_AUTH_FACTORS]});
            queryClient.invalidateQueries({queryKey: [GET_FUTURAE_INFO]});
        },
    });
};

export interface IUpdateDevice {
    id: string;
    factor: AuthFactors;
}

const useUnenrollDevice = () => {
    const authenticatedClient = useClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (request: IUpdateDevice) =>
            authenticatedClient(
                `/web_api/futurae/unenroll_device/${request.id}`,
                {
                    data: {
                        factor: request.factor,
                    },
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/vnd.api+json',
                    },
                },
            ),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: [GET_FUTURAE_INFO]});
            queryClient.invalidateQueries({queryKey: [GET_AUTH_FACTORS]});
        },
    });
};

const useUpdateDefaultDevice = () => {
    const authenticatedClient = useClient();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (request: IUpdateDevice) =>
            authenticatedClient(
                `/web_api/futurae/update_factor/${request.id}`,
                {
                    data: {
                        factor: request.factor,
                    },
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/vnd.api+json',
                    },
                },
            ),
        onSuccess: () =>
            queryClient.invalidateQueries({queryKey: [GET_FUTURAE_INFO]}),
    });
};

export {
    useConfirmSMS,
    useAddQRCode,
    useGetQRAuthStatus,
    useGetAuthFactors,
    useGetFuturaeInfo,
    useEnrollRegistration,
    useConfirmMobileRegistration,
    useEnrollOnResetPassword,
    useUnenrollDevice,
    useUpdateDefaultDevice,
    useGetCardSecureInfo,
    useGetTwoFALogin,
    useGetCardPin,
    useGetOnboardingTwoFA,
    useGetPasswordResetTwoFA,
    useRetrigger2FAForCardOrder,
};
