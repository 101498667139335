import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {immer} from 'zustand/middleware/immer';

export type TwoFASuccessHandler = {
    passCode: string;
    token?: string;
    user?: string;
};

interface TwoFAState {
    isOpen: boolean;
    isVerifying: boolean;
    isResending: boolean;
    factor?: 'approve' | 'sms';
    mfaClaimUUID?: string;
    factors?: any;
    qrcode_url?: string;
    error?: string;
    onSuccess?: (data: TwoFASuccessHandler) => void;
    onFailure?: (reason?: any) => void;
    onResend?: () => void;
    showTwoFA: (data: Partial<TwoFAState>) => void;
    closeTwoFA: () => void;
    setVerifying: (isVerifying: boolean) => void;
    setResending: (isResending: boolean) => void;
    setError: (error?: string) => void;
}

export const useTwoFAStore = create(
    devtools(
        immer<TwoFAState>((set, get) => ({
            isOpen: false,
            isVerifying: false,
            isResending: false,
            showTwoFA: data => set({...data, isOpen: true}),
            closeTwoFA: () => set({isOpen: false}),
            setVerifying: isVerifying => set({isVerifying}),
            setResending: isResending => set({isResending}),
            setError: error => set({error}),
        })),
    ),
);
