const SvgNewInfo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M11.25 16.75h1.5V11h-1.5v5.75ZM12 9.29c.229 0 .42-.078.575-.232a.782.782 0 0 0 .233-.576.782.782 0 0 0-.232-.575.781.781 0 0 0-.576-.232.782.782 0 0 0-.575.232.782.782 0 0 0-.233.575c0 .23.078.42.232.576A.782.782 0 0 0 12 9.29Zm.002 12.211a9.255 9.255 0 0 1-3.705-.748 9.596 9.596 0 0 1-3.017-2.03 9.591 9.591 0 0 1-2.032-3.016 9.245 9.245 0 0 1-.748-3.704c0-1.314.25-2.549.748-3.705a9.597 9.597 0 0 1 2.03-3.017A9.592 9.592 0 0 1 8.294 3.25a9.245 9.245 0 0 1 3.704-.749c1.314 0 2.55.25 3.705.748a9.596 9.596 0 0 1 3.018 2.03 9.593 9.593 0 0 1 2.03 3.016A9.247 9.247 0 0 1 21.5 12c0 1.314-.25 2.549-.748 3.705a9.596 9.596 0 0 1-2.03 3.017 9.592 9.592 0 0 1-3.016 2.031 9.247 9.247 0 0 1-3.704.748ZM12 20c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.234 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.234.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20Z"
        />
    </svg>
);

export default SvgNewInfo;
