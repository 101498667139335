const Reminder = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M11.998 21.693c-.497 0-.923-.177-1.276-.531a1.743 1.743 0 0 1-.53-1.277h3.616c0 .499-.178.925-.532 1.278-.354.353-.78.53-1.278.53ZM4.5 18.885v-1.5h1.808V9.923c0-1.344.415-2.533 1.245-3.567a5.518 5.518 0 0 1 3.197-1.982V3.75c0-.347.121-.642.364-.885S11.652 2.5 12 2.5s.642.122.886.365c.243.243.365.538.365.885v.2a4.871 4.871 0 0 0-.39.904 4.07 4.07 0 0 0-.177.954 9.103 9.103 0 0 0-.333-.054 2.488 2.488 0 0 0-.35-.023c-1.158 0-2.146.41-2.964 1.228-.819.819-1.228 1.807-1.228 2.964v7.462h8.384v-6.252c.23.082.472.146.727.192.255.047.513.067.773.06v6H19.5v1.5h-15Zm12.982-9.683a2.655 2.655 0 0 1-1.948-.801 2.649 2.649 0 0 1-.803-1.948c0-.763.267-1.413.802-1.948a2.649 2.649 0 0 1 1.947-.803c.763 0 1.412.268 1.948.802.535.535.803 1.184.803 1.947 0 .764-.268 1.413-.802 1.948a2.649 2.649 0 0 1-1.947.803Z"
        />
    </svg>
);

export default Reminder;
