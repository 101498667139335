import {Continents, ICountry} from 'types/api';
import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {immer} from 'zustand/middleware/immer';

type CountryValidityStoreState = {
    countriesByContinent: Partial<Record<Continents, ICountry[]>>;
    enabledCountries: string[];
};

type CountryValidityStoreActions = {
    setCountriesByContinent: (countries: ICountry[]) => void;
    setCountriesEnabled: (countries: ICountry[]) => void;
    toggleCountry: (country: string) => void;
    toggleContinent: (continent: Continents, enable: boolean) => void;
    resetState: () => void;
};

const defaultStore: CountryValidityStoreState = {
    countriesByContinent: {},
    enabledCountries: [],
};

export const countryValidityStore = create(
    devtools(
        immer<CountryValidityStoreState & CountryValidityStoreActions>(
            (set, get) => ({
                ...defaultStore,
                setCountriesByContinent(countries) {
                    const countriesByContinent: {
                        [key in Continents]: ICountry[];
                    } = {
                        'EEA/CH': [],
                        Europe: [],
                        'North America': [],
                        'South America': [],
                        Africa: [],
                        Asia: [],
                        Antarctica: [],
                        Oceania: [],
                    };

                    countries.forEach(country => {
                        const {continent, eeaMember} = country;

                        if (eeaMember) {
                            countriesByContinent['EEA/CH'].push(country);
                        }

                        countriesByContinent[continent].push(country);
                    });

                    set(state => {
                        state.countriesByContinent = countriesByContinent;
                    });
                },
                toggleContinent(continent, enable) {
                    const {enabledCountries, countriesByContinent} = get();
                    const countries = countriesByContinent[continent] || [];
                    let newEnabledCountries: string[] = [];
                    if (enable) {
                        newEnabledCountries = [
                            ...enabledCountries,
                            ...countries
                                .filter(
                                    c => !enabledCountries.includes(c['@id']),
                                )
                                .map(c => c['@id']),
                        ];
                    } else {
                        newEnabledCountries = enabledCountries.filter(
                            ec =>
                                !countries.find(
                                    country => country['@id'] === ec,
                                ),
                        );
                    }
                    set(state => {
                        state.enabledCountries = newEnabledCountries;
                    });
                },
                setCountriesEnabled(countries) {
                    set(state => {
                        state.enabledCountries =
                            countries?.map(c => c['@id']) || [];
                    });
                },
                toggleCountry(country) {
                    const {enabledCountries} = get();
                    if (enabledCountries.includes(country)) {
                        set(state => {
                            state.enabledCountries =
                                state.enabledCountries.filter(
                                    ec => ec !== country,
                                );
                        });
                    } else {
                        set(state => {
                            state.enabledCountries = [
                                ...state.enabledCountries,
                                country,
                            ];
                        });
                    }
                },
                resetState() {
                    set(state => {
                        state.countriesByContinent = {};
                        state.enabledCountries = [];
                    });
                },
            }),
        ),
    ),
);
