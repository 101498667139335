import CountdownTimer from 'components/ui/CountdownTimer/CountdownTimer';
import useModal from 'components/ui/ModalContainer/useModal';
import ConfirmationOverlay from 'components/ui/Overlays/ConfirmationOverlay/ConfirmationOverlay';
import {useAuth} from 'context/auth-context';
import {useEffect, useRef} from 'react';
import {useIdleTimer, workerTimers} from 'react-idle-timer';
import styles from './IdleTimer.module.scss';
import {differenceInMinutes, format, parse} from 'date-fns';
import {getItemFromStorage, setItemToStorage} from 'helpers/localStorage';

export const LAST_SEEN_KEY = 'last-seen-at';

const IdleTimer = () => {
    const {openModalWithContent, closeModal} = useModal();
    const {logout} = useAuth();
    const activateRef = useRef<(() => boolean) | null>(null);

    const handlePromptTimeout = () => {
        openModalWithContent(
            'idleTimeoutPrompt',
            <ConfirmationOverlay
                title="It seems you are away..."
                description={
                    <div className={styles.content}>
                        <p>
                            After 5 minutes of inactivity we automatically log
                            you out for security reasons. Please confirm if you
                            are still here.
                        </p>
                        <div className={styles.counter}>
                            <CountdownTimer
                                timeLimit={30}
                                alertThreshold={5}
                                warningThreshold={10}
                            />
                        </div>
                    </div>
                }
                onConfirm={() => {
                    activate();
                    closeModal('idleTimeoutPrompt');
                }}
                confirmLabel="I'm still here"
                hideClose
            />,
        );
    };

    const {activate} = useIdleTimer({
        onPrompt: handlePromptTimeout,
        onIdle: () => logout(),
        startOnMount: true,
        timers: workerTimers,
        crossTab: true,
        promptBeforeIdle: 30 * 1000, // 30 secs
        timeout: 5 * 60 * 1000, // 5 mins
    });

    useEffect(() => {
        activateRef.current = activate;
    }, [activate]);

    useEffect(() => {
        const LAST_SEEN_FORMAT = 'PPpp';
        try {
            const lastSeenRaw = getItemFromStorage(LAST_SEEN_KEY);
            if (!!lastSeenRaw) {
                const lastSeenParsed = parse(
                    lastSeenRaw,
                    LAST_SEEN_FORMAT,
                    new Date(),
                );
                const diffInMinutes = differenceInMinutes(
                    lastSeenParsed,
                    new Date(),
                );
                // instead of 5 mins use 4.9 because we do the interval in 5 secs
                if (Math.abs(diffInMinutes) >= 4.9) {
                    logout();
                }
            }
        } catch (e) {
            console.error(e);
        }

        const interval = setInterval(() => {
            try {
                setItemToStorage(
                    LAST_SEEN_KEY,
                    format(new Date(), LAST_SEEN_FORMAT),
                );
            } catch (e) {
                console.log(e);
            }
        }, 5 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return null;
};

export default IdleTimer;
