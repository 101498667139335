import {useState, useEffect, useMemo, useRef} from 'react';
import {useHistory} from 'react-router';
import {Alert, Button, Spinner} from 'react-bootstrap';
import {
    IIdentification,
    useGetCurrentIdentification,
    useIdentification,
} from 'api/identification';
import {PATH} from 'constants/common';
import Loader from 'components/ui/AmnisLoader/AmnisLoader';
import {Link} from 'react-router-dom';
import {useAuth} from 'context/auth-context';
import {formatDateInZurichTZ} from 'helpers/dates';
import Form from 'components/ui/Form/Form/Form';
import CheckBoxGroup from 'components/ui/Form/OnboardingCheckBoxGroup/OnboardingCheckBoxGroup';
import classNames from 'classnames';
import {OnboardingPageType} from 'types/onboarding';
import {ICrpBpListItem} from 'api/business-partner';
import {IDENTIFICATION_LIST_CACHE} from 'constants/cache';
import {IDefaultStepProps} from '../useOnboarding';
import StepTitle from '../components/StepTitle';
import NextStepButton from '../components/NextStepButton';
import Illustration from '../components/illustrations/3.svg';

import styles from './Steps.module.scss';
import {useQueryClient} from '@tanstack/react-query';
import {useEventSourceStore} from 'context/event-source-context';

export enum IdentificationChannel {
    DESKTOP = 'desktop',
    MOBILE = 'phone',
}

const IDENTIFICATION_CHANNEL_OPTIONS = [
    {value: IdentificationChannel.DESKTOP, label: 'Desktop (Computer)'},
    {value: IdentificationChannel.MOBILE, label: 'Mobile'},
];

const Identification = (props: IDefaultStepProps) => {
    const [approvedCrp, setApprovedCrp] = useState<boolean>(false);
    const {
        crpList,
        inReview,
        setOnboardingPageType,
        onSuccess,
        activeBusinessPartner,
    } = props;
    const {user} = useAuth();
    const currentCrp = crpList?.find(
        crp => crp.crp.user && crp.crp.user === user['@id'],
    );

    const history = useHistory();
    const {
        data: currentIdentification,
        isLoading: isCurrentIdentificationLoading,
        isFetching: isCurrentIdentificationFetching,
        refetch: refetchIdentification,
    } = useGetCurrentIdentification(currentCrp?.crp);
    const {mutate: identify, isPending: isLoading} = useIdentification();

    const registerListener = useEventSourceStore(
        state => state.registerListener,
    );
    const [selectedChannel, setSelectedChannel] =
        useState<IdentificationChannel | null>(null);
    const queryClient = useQueryClient();
    const listenerRef = useRef<(() => void) | null>(null);

    const hasIdentification = useMemo(
        () =>
            currentIdentification &&
            ((currentIdentification.status.toLowerCase() === 'success' &&
                !currentCrp?.crp.needIdentification) ||
                currentIdentification.status.toLowerCase() === 'pending'),
        [currentCrp, currentIdentification],
    );

    useEffect(() => {
        if (!listenerRef.current) {
            listenerRef.current = registerListener('identification', {
                area: 'identification',
                callback: () => {
                    refetchIdentification();
                    setSelectedChannel(null);
                },
            });
        }

        return () => listenerRef.current?.();
    }, []);

    useEffect(() => {
        if (hasIdentification) {
            setOnboardingPageType?.(OnboardingPageType.BLUE);
        } else {
            setOnboardingPageType?.(OnboardingPageType.WHITE);
        }

        return () => setOnboardingPageType?.(OnboardingPageType.WHITE);
    }, [
        currentIdentification,
        setOnboardingPageType,
        currentCrp,
        hasIdentification,
    ]);

    const handleIdentify = (values: any) => {
        const {channel} = values;
        identify(
            {
                channel,
            },
            {
                onSuccess: data => {
                    if (channel === IdentificationChannel.DESKTOP) {
                        window.location.href = data.url;
                    } else {
                        setSelectedChannel(channel);
                    }
                },
                onError: () => {
                    history.push(PATH.JUMIO_ERROR);
                },
            },
        );
    };

    const renderButtons = () => (
        <Form onSubmit={handleIdentify}>
            <CheckBoxGroup
                name="channel"
                label="Please choose an option"
                options={IDENTIFICATION_CHANNEL_OPTIONS}
                type="radio"
                variant="dark"
            />
            <NextStepButton loading={isLoading} />
        </Form>
    );

    const renderResult = (channel: IdentificationChannel) => {
        if (channel === IdentificationChannel.MOBILE) {
            return (
                <Alert variant="success" className="mt-3">
                    We sent you an sms with the link to your mobile phone.
                    Please open the link and proceed with the identification.
                    After finishing with it, please proceed with it here or on
                    your mobile phone
                </Alert>
            );
        }
        return (
            <p>
                After the identification process is finished, please click{' '}
                <Link to={PATH.SETTINGS}>here to sign the contract</Link>
            </p>
        );
    };

    const renderCurrentIdentification = (
        identification?: IIdentification,
        crpBp?: ICrpBpListItem,
    ) => {
        if (identification?.status.toLowerCase() === 'pending') {
            return (
                <>
                    <h1>
                        Almost there! <br /> We are still waiting for the
                        confirmation from the provider.
                    </h1>
                    <div className={styles.identificationResultContainer}>
                        <div className={styles.resultTitle}>
                            <div
                                className={classNames(
                                    styles.resultIcon,
                                    styles.pending,
                                )}
                            />
                            <div>
                                <h3 className={styles.identificationResultText}>
                                    Your identity verification is in progress.
                                </h3>
                                <p className={styles.identificationResultText}>
                                    We&apos;re reviewing your information and
                                    we&apos;ll update you in a few minutes via
                                    email.
                                </p>
                            </div>
                            <div
                                className={styles.identificationLoadingSpinnger}
                            >
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{marginRight: '5px'}}
                                />
                            </div>
                        </div>
                        <p className={styles.identificationResultText}>
                            If you have not yet started the process, simply
                            click on “Start identification”.
                        </p>
                        <div
                            className={classNames(
                                styles.buttonRow,
                                styles.identificationButtonRow,
                            )}
                        >
                            <a href={identification.url}>
                                Start identification
                            </a>
                            {/* TODO - change bootstrap button to components/ui/Button if merged */}
                            <Button
                                onClick={() =>
                                    queryClient.invalidateQueries({
                                        queryKey: [IDENTIFICATION_LIST_CACHE],
                                    })
                                }
                                // isLoading={isCurrentIdentificationLoading}
                                variant="outline-primary"
                                disabled={
                                    isCurrentIdentificationLoading ||
                                    isCurrentIdentificationFetching
                                }
                            >
                                Refresh
                            </Button>
                        </div>
                    </div>
                </>
            );
        }

        if (identification?.status.toLowerCase() === 'success') {
            return (
                <>
                    <h1>
                        Almost there! <br /> Now conveniently sign.
                    </h1>
                    <div className={styles.identificationResultContainer}>
                        <div className={styles.resultTitle}>
                            <div
                                className={classNames(
                                    styles.resultIcon,
                                    styles.success,
                                )}
                            />
                            <div>
                                <h3 className={styles.identificationResultText}>
                                    Your identification was successful!
                                </h3>
                                <p className={styles.identificationResultText}>
                                    Please confirm briefly that your information
                                    is correct
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className={styles.detailRowEntry}>
                                <p className={styles.key}>First name</p>
                                <p className={styles.value} data-notranslate>
                                    <span>
                                        {crpBp?.crp.identifiedFirstName}
                                    </span>
                                </p>
                            </div>
                            <div className={styles.detailRowEntry}>
                                <p className={styles.key}>Last name</p>
                                <p className={styles.value} data-notranslate>
                                    <span>{crpBp?.crp.identifiedLastName}</span>
                                </p>
                            </div>
                            <div className={styles.detailRowEntry}>
                                <p className={styles.key}>ID Document type</p>
                                <p className={styles.value} data-notranslate>
                                    <span>{crpBp?.crp.idType}</span>
                                </p>
                            </div>
                            <div className={styles.detailRowEntry}>
                                <p className={styles.key}>ID document number</p>
                                <p className={styles.value} data-notranslate>
                                    <span>{crpBp?.crp.idNumber}</span>
                                </p>
                            </div>
                            <div className={styles.detailRowEntry}>
                                <p className={styles.key}>
                                    ID document expire date
                                </p>
                                <p className={styles.value} data-notranslate>
                                    <span>
                                        {crpBp?.crp.idExpiryDate &&
                                            formatDateInZurichTZ(
                                                crpBp.crp.idExpiryDate,
                                            )}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <Form
                        onSubmit={() => {
                            onSuccess({
                                refetchCrpList: true,
                                refetchContractList: true,
                            });
                        }}
                    >
                        <CheckBoxGroup
                            name="accept"
                            label=""
                            options={[
                                {
                                    label: "Yes that's me. I confirm that the above information is correct",
                                    value: 'yes',
                                },
                            ]}
                            defaultValue="yes"
                        />
                        <NextStepButton className={styles.bluePageButton} />
                    </Form>
                </>
            );
        }

        return (
            <>
                <h1>Hmm, something went differently than expected.</h1>
                <div className={styles.identificationResultContainer}>
                    <div className={styles.resultTitle}>
                        <div
                            className={classNames(
                                styles.resultIcon,
                                styles.error,
                            )}
                        />
                        <div>
                            <h3 className={styles.identificationResultText}>
                                There was an error identifying you
                            </h3>
                        </div>
                    </div>
                    <p className={styles.identificationResultText}>
                        Please decide again with which device you would like to
                        carry out the identification.
                    </p>
                    {selectedChannel === null
                        ? renderButtons()
                        : renderResult(selectedChannel)}
                </div>
            </>
        );
    };

    const handleReportIncorrectCrp = () => {
        window.Intercom('show');
        window.Intercom(
            'showNewMessage',
            `Issue: incorrect UBO is mapped to user account\nUBO: ${currentCrp?.crp[
                '@id'
            ]
                .split('/')
                .pop()}\nUser: ${user['@id']
                .split('/')
                .pop()}\nBP: ${activeBusinessPartner['@id']
                .split('/')
                .pop()}\n\nComment: `,
        );
    };

    const renderNewIdentification = () => (
        <>
            <StepTitle
                title={
                    <span>
                        Let&apos;s check your identity,&nbsp;
                        <span data-notranslate>
                            {user.firstName} {user.lastName}
                        </span>
                    </span>
                }
                subTitle={
                    <>
                        <div>
                            The next step in the&nbsp;
                            {inReview ? 'review' : 'onboarding'}&nbsp; process
                            is to identify you. For that please prepare your ID
                            or Passport. The identification will be done by a
                            third-party provider called Jumio
                        </div>
                        {!approvedCrp ? (
                            <>
                                <div>
                                    We are about to identify&nbsp;
                                    <var data-var="name">
                                        <span className={styles.bold}>
                                            {currentCrp?.crp.firstName}&nbsp;
                                            {currentCrp?.crp.lastName}
                                        </span>
                                    </var>
                                    . Please confirm that you are this
                                    person&nbsp;
                                </div>
                                <div className={styles.identificationButtonRow}>
                                    <Button
                                        onClick={() =>
                                            handleReportIncorrectCrp()
                                        }
                                        variant="outline-danger"
                                    >
                                        No
                                    </Button>
                                    <Button
                                        onClick={() => setApprovedCrp(true)}
                                    >
                                        Yes
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    We recommend using your passport for the
                                    identification and please make sure you have
                                    enough light in your room.
                                </div>
                                <div>
                                    You can decide whether you want to identify
                                    yourself with your computer or with your
                                    mobile phone. In the case of the latter, we
                                    will send you the link in an SMS.
                                </div>
                                {selectedChannel === null
                                    ? renderButtons()
                                    : renderResult(selectedChannel)}
                            </>
                        )}
                    </>
                }
            />
        </>
    );

    if (isCurrentIdentificationLoading || isLoading || !crpList?.length) {
        return <Loader />;
    }

    return (
        <div className={styles.form}>
            {hasIdentification ? (
                <div className={styles.identificationContainer}>
                    <Illustration />
                    {renderCurrentIdentification(
                        currentIdentification,
                        currentCrp,
                    )}
                </div>
            ) : (
                renderNewIdentification()
            )}
        </div>
    );
};

export default Identification;
