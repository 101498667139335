import * as Yup from 'yup';
import {isPossiblePhoneNumber} from 'react-phone-number-input';
import {CrpVariants} from 'constants/businessPartnerAttributes';
import {LegalType, StepTypes} from 'types/onboarding';
import {COUNTRY_CODES_FOR_STATE} from 'constants/statesProvinces';
import {REASON_FOR_AMNIS, ReasonForAmnis} from 'types/api';
import {
    INCOMING_AND_OUTGOING_PAYMENTS_STEPS,
    IcomingAndOutgoingPayments,
} from 'components/pages/OnboardingPage/steps/IncomingPayments';

export const companyFormValidationSchema = Yup.object({
    countryOfIncorporation: Yup.string().nullable().required('Required'),
    companyName: Yup.string().required('Required'),
    legalForm: Yup.string().nullable().required('Required'),
    zip: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    stateOrProvince: Yup.string()
        .nullable()
        .when('countryOfIncorporation', {
            is: country =>
                COUNTRY_CODES_FOR_STATE.includes(country?.split('/').pop()),
            then: Yup.string().required('Required'),
        }),
    gwgInfo: Yup.object({
        incorporationNumber: Yup.string().required('Required'),
        businessIndustry: Yup.string().nullable().required('Required'),
    }),
    dateOfIncorporation: Yup.string().required('Required'),
    WIRCustomer: Yup.boolean(),
    WIRCustomerId: Yup.string()
        .nullable()
        .when('WIRCustomer', {
            is: WIRCustomer => !!WIRCustomer,
            then: Yup.string().required('Required'),
        }),
});
export type CompanyFormValues = ReturnType<
    typeof companyFormValidationSchema.validateSync
>;

export const bpIndividualValidationSchema = Yup.object().shape({
    legalForm: Yup.string().required(),
    countryOfIncorporation: Yup.string().nullable().required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    zip: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    stateOrProvince: Yup.string()
        .nullable()
        .when('country', {
            is: country =>
                COUNTRY_CODES_FOR_STATE.includes(country?.split('/').pop()),
            then: Yup.string().required('Required'),
        }),
    email: Yup.string().email('Invalid email').required('Required'),
    phoneNumber: Yup.string()
        .nullable()
        .required('Required')
        .test('valid_phonenumber', 'Invalid phone number', value =>
            isPossiblePhoneNumber(value),
        ),
});

export type IndividualFormValues = ReturnType<
    typeof bpIndividualValidationSchema.validateSync
>;

export const businessActivitiesValidationSchema = Yup.object({
    inReview: Yup.boolean(),
    website: Yup.string().nullable(),
    sourceOfFundsFiles: Yup.array().of(
        Yup.mixed().test(
            'isFile',
            'Please upload a valid file',
            (files: File[]) => files.length === 0 || files[0] instanceof File,
        ),
    ),
    companyOrgChartFiles: Yup.array().of(
        Yup.mixed().test(
            'isFile',
            'Please upload a valid file',
            (files: File[]) => files.length === 0 || files[0] instanceof File,
        ),
    ),
    referralType: Yup.string()
        .nullable()
        .when(['inReview', 'gwgInfo.isIndividual'], {
            is: (inReview, isIndividual) => !inReview && !!isIndividual,
            then: Yup.string().nullable().required('Required'),
        }),
    gwgInfo: Yup.object({
        isIndividual: Yup.boolean(),
        sourceOfFunds: Yup.string()
            .nullable()
            .when('isIndividual', {
                is: isIndividual => !!isIndividual,
                then: Yup.string().nullable().required('Required'),
            }),
        customSourceOfFunds: Yup.string()
            .nullable()
            .when('sourceOfFunds', {
                is: sourceOfFunds => sourceOfFunds === 'OTHERS',
                then: Yup.string().required('Required'),
            }),
        businessIndustry: Yup.string()
            .nullable()
            .when(['isIndividual', 'isCompanyOnAmnis', 'ownerOfCompany'], {
                is: (isIndividual, isCompanyOnAmnis, ownerOfCompany) => {
                    return (
                        !!isIndividual &&
                        (isCompanyOnAmnis === '0' || ownerOfCompany === '0')
                    );
                },
                then: Yup.string().required('Required'),
            }),
        numberEmployees: Yup.string()
            .nullable()
            .when('isIndividual', {
                is: isIndividual => !isIndividual,
                then: Yup.string().required('Required'),
            }),
        countryOfBusiness: Yup.array()
            .of(Yup.string())
            .when('isIndividual', {
                is: isIndividual => !isIndividual,
                then: Yup.array().of(Yup.string()).required('Required'),
            }),
        isGroup: Yup.string()
            .nullable()
            .when('isIndividual', {
                is: isIndividual => !isIndividual,
                then: Yup.string().required('Required'),
            }),
        businessActivities: Yup.array()
            .of(Yup.string())
            .nullable()
            .when('isIndividual', {
                is: isIndividual => !isIndividual,
                then: Yup.array().of(Yup.string()).required('Required'),
            }),
        physicalPresence: Yup.array()
            .of(Yup.string())
            .nullable()
            .when('isIndividual', {
                is: isIndividual => !isIndividual,
                then: Yup.array().of(Yup.string()).required('Required'),
            }),
        ownerOfCompany: Yup.string()
            .nullable()
            .when('isIndividual', {
                is: isIndividual => !!isIndividual,
                then: Yup.string().required('Required'),
            }),
        isCompanyOnAmnis: Yup.string()
            .nullable()
            .when('ownerOfCompany', {
                is: ownerOfCompany => ownerOfCompany === '1',
                then: Yup.string().required('Required'),
            }),
    }),
});
export type BusinessActivitiesCompanyFormValues = ReturnType<
    typeof businessActivitiesValidationSchema.validateSync
>;

export const businessAttributesValidationSchema = Yup.object({
    inReview: Yup.boolean(),
    referralType: Yup.string()
        .nullable()
        .when('inReview', {
            is: inReview => !inReview,
            then: Yup.string().nullable().required('Required'),
        }),
    gwgInfo: Yup.object({
        operationalBusiness: Yup.boolean().nullable(),
        website: Yup.string().nullable().url(),
        returnBudget: Yup.string().nullable().required('Required'),
        referralCode: Yup.string()
            .nullable()
            .when('refferalType', {
                is: referralType => referralType === 'Clientreferral',
                then: Yup.string().nullable().required('Required'),
            }),
        sourceOfFunds: Yup.string().nullable().required('Required'),
        customSourceOfFunds: Yup.string()
            .nullable()
            .when('sourceOfFunds', {
                is: sourceOfFunds => sourceOfFunds === 'OTHERS',
                then: Yup.string().required('Required'),
            }),
    }),
});
export type BusinessAttributesCompanyFormValues = ReturnType<
    typeof businessAttributesValidationSchema.validateSync
>;

export const reasonForAmnisValidationSchema = Yup.object({
    gwgInfo: Yup.object({
        reasonForAmnis: Yup.array<ReasonForAmnis[]>()
            .of(Yup.string().oneOf([...REASON_FOR_AMNIS]))
            .required('Required'),
    }),
});
export type ReasonForAmnisFormValues = ReturnType<
    typeof reasonForAmnisValidationSchema.validateSync
>;

export const generalPaymentsValidationSchema = Yup.object({
    gwgInfo: Yup.object({
        paymentTypes: Yup.array()
            .min(1, 'Required')
            .of(Yup.string())
            .nullable(),
        paymentSize: Yup.string().nullable().required('Required'),
        paymentVolume: Yup.string().nullable().required('Required'),
    }),
});
export type GeneralPaymentsFormValues = ReturnType<
    typeof generalPaymentsValidationSchema.validateSync
>;

export const debitCardsValidationSchema = Yup.object({
    gwgInfo: Yup.object({
        legalType: Yup.string().nullable(),
        informationCollected: Yup.string().when('legalType', {
            is: legalType => legalType === LegalType.COMPANY,
            then: Yup.string().required('Required'),
        }),
        cardPurpose: Yup.array().of(Yup.string()).required('Required'),
        cardVolume: Yup.string().nullable().required('Required'),
        cardCount: Yup.string()
            .nullable()
            .when('legalType', {
                is: legalType => legalType === LegalType.COMPANY,
                then: Yup.string().nullable().required('Required'),
            }),
    }),
});
export type DebitCardsFormValues = ReturnType<
    typeof debitCardsValidationSchema.validateSync
>;

export const fxValidationSchema = Yup.object({
    gwgInfo: Yup.object({
        currencyVolume: Yup.string().nullable().required('Required'),
        relevantCurrencies: Yup.array()
            .of(Yup.string())
            .nullable()
            .min(1, 'Required'),
    }),
});
export type FxStepFormValues = ReturnType<
    typeof fxValidationSchema.validateSync
>;

export const bpCreateUserValidationSchema = Yup.object().shape({
    gwgInfo: Yup.object().shape({
        formOfControl: Yup.string().required('Required'),
        assetsOwner: Yup.string().required('Required'),
    }),
    users: Yup.array().of(
        Yup.object().shape({
            gender: Yup.string().required('Required'),
            loggedInUser: Yup.string().required('Required'),
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email').required('Required'),
            nationality: Yup.string().nullable().required('Required'),
            dateOfBirth: Yup.string().required('Required'),
            street: Yup.string().required('Required'),
            stateOrProvince: Yup.string().required('Required'),
            city: Yup.string().required('Required'),
            zip: Yup.string().required('Required'),
            country: Yup.string().nullable().required('Required'),
        }),
    ),
});

export const bpCreateUserIndividualValidationSchema = Yup.object().shape({
    users: Yup.array().of(
        Yup.object().shape({
            gender: Yup.string().required('Required'),
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            email: Yup.string().email('Invalid email').required('Required'),
            nationality: Yup.string().nullable().required('Required'),
            dateOfBirth: Yup.string().required('Required'),
            street: Yup.string().required('Required'),
            stateOrProvince: Yup.string()
                .nullable()
                .when('country', {
                    is: country =>
                        COUNTRY_CODES_FOR_STATE.includes(
                            country?.split('/').pop(),
                        ),
                    then: Yup.string().required('Required'),
                }),
            city: Yup.string().required('Required'),
            zip: Yup.string().required('Required'),
            country: Yup.string().nullable().required('Required'),
        }),
    ),
});

export const bpCreateSignersValidationSchema = Yup.object().shape({
    signers: Yup.array().of(
        Yup.object().shape({
            gender: Yup.string().required('Required'),
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            role: Yup.string().required('Required'),
            email: Yup.string().email().required('Required'),
            phoneNumber: Yup.string()
                .nullable()
                .required('Required')
                .test('valid_phonenumber', 'Invalid phone number', value =>
                    isPossiblePhoneNumber(value),
                ),
            nationality: Yup.string().required('Required'),
            country: Yup.string().required('Required'),
            loggedInUser: Yup.string().required('Required'),
        }),
    ),
});

export const bpSelectAdminValidationSchema = Yup.object().shape({
    admin: Yup.string().required('Required'),
});

export const bpEditAddressValidationSchema = Yup.object().shape({
    zip: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    stateOrProvince: Yup.string()
        .nullable()
        .when('country', {
            is: country =>
                COUNTRY_CODES_FOR_STATE.includes(country?.split('/').pop()),
            then: Yup.string().required('Required'),
        }),
});

export const addNewCrpValidationSchema = Yup.object().shape({
    onAmnis: Yup.string().required('Required'),
    email: Yup.string().when('onAmnis', {
        is: onAmnis => onAmnis === 'yes',
        then: Yup.string().required('Required'),
    }),
    firstName: Yup.string().when('onAmnis', {
        is: onAmnis => onAmnis === 'yes',
        then: Yup.string().required('Required'),
    }),
    lastName: Yup.string().when('onAmnis', {
        is: onAmnis => onAmnis === 'yes',
        then: Yup.string().required('Required'),
    }),
});

export const crpFormConfidentialBaseValidationSchema = Yup.object().shape({
    lastName: Yup.string(),
    firstName: Yup.string(),
    email: Yup.string(),
    professionalPosition: Yup.string().nullable().required('Required'),
    highestEducation: Yup.string().nullable().required('Required'),
    role: Yup.string().nullable().required('Required'),
});

export const crpFormBaseValidationSchema = (variant: CrpVariants) => {
    const base = Yup.object().shape({
        lastName: Yup.string().required('Required'),
        firstName: Yup.string().required('Required'),
        gender: Yup.string().oneOf(['male', 'female']).required('Required'),
        country: Yup.string().required('Required'),
        email: Yup.string().nullable(),
        professionalPosition: Yup.string().nullable().required('Required'),
        highestEducation: Yup.string().nullable().required('Required'),
        role: Yup.string().nullable().required('Required'),
    });
    if (variant === CrpVariants.SIGNER) {
        return base;
    }
    if (variant === CrpVariants.OWNER) {
        return base.concat(
            Yup.object().shape({
                nationality: Yup.string().nullable().required('Required'),
                dateOfBirth: Yup.string().nullable().required('Required'),
                street: Yup.string().required('Required'),
                zip: Yup.string().required('Required'),
                city: Yup.string().required('Required'),
            }),
        );
    }
    return base.concat(
        Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Required'),
            phoneNumber: Yup.string()
                .nullable()
                .required('Required')
                .test('valid_phonenumber', 'Invalid phone number', value => {
                    if (!value) return false;
                    return isPossiblePhoneNumber(value);
                }),
            nationality: Yup.string().nullable().required('Required'),
            dateOfBirth: Yup.string().nullable().required('Required'),
            street: Yup.string().required('Required'),
            zip: Yup.string().required('Required'),
            city: Yup.string().required('Required'),
        }),
    );
};

export const crpFormValidationSchema = (
    variant: CrpVariants,
    confidential: boolean,
    legalType: LegalType | null,
    formOfControl?: string,
) => {
    switch (variant) {
        case CrpVariants.OWNER: {
            if (legalType === LegalType.INDIVIDUAL) {
                if (confidential) {
                    return crpFormConfidentialBaseValidationSchema;
                }
                return crpFormBaseValidationSchema(variant);
            }
            if (!confidential) {
                if (formOfControl === '1') {
                    return Yup.object()
                        .shape({
                            directOwner: Yup.string().required('Required'),
                            ownerPercentage: Yup.string()
                                .nullable()
                                .when('directOwner', {
                                    is: directOwner => directOwner === 'yes',
                                    then: Yup.string()
                                        .nullable()
                                        .required('Required'),
                                }),
                        })
                        .concat(crpFormBaseValidationSchema(variant));
                }
                return crpFormBaseValidationSchema(variant);
            }
            if (formOfControl === '1') {
                return Yup.object()
                    .shape({
                        directOwner: Yup.string().required('Required'),
                        ownerPercentage: Yup.string()
                            .nullable()
                            .when('directOwner', {
                                is: directOwner => directOwner === 'yes',
                                then: Yup.string()
                                    .nullable()
                                    .required('Required'),
                            }),
                    })
                    .concat(crpFormConfidentialBaseValidationSchema);
            }
            return crpFormConfidentialBaseValidationSchema;
        }
        case CrpVariants.CONTRACT_SIGNER:
        case CrpVariants.SIGNER: {
            const base = Yup.object().shape({
                collectiveSigningRight: Yup.string().required('Required'),
                role: Yup.string().required('Required'),
            });
            if (!confidential) {
                return base.concat(crpFormBaseValidationSchema(variant));
            }
            return base.concat(crpFormConfidentialBaseValidationSchema);
        }
        case CrpVariants.ADMIN:
            return confidential
                ? crpFormConfidentialBaseValidationSchema
                : crpFormBaseValidationSchema(variant);
        default:
            throw new Error(`Invalid CrpCardsVariant: ${variant}`);
    }
};

export const pepValidationSchema = Yup.object({
    pep: Yup.string().required('Required'),
    pepDescription: Yup.string().when('pep', {
        is: pep => pep === 'yes',
        then: Yup.string().required('Required'),
    }),
});
export type PepFormValues = ReturnType<typeof pepValidationSchema.validateSync>;

export const pepBpValidationSchema = Yup.object({
    gwgInfo: Yup.object({
        pep: Yup.string().required('Required'),
        pepDescription: Yup.string().when('pep', {
            is: pep => pep === 'yes',
            then: Yup.string().required('Required'),
        }),
    }),
});
export type PepBpFormValues = ReturnType<
    typeof pepBpValidationSchema.validateSync
>;

export const sourceOfWealthValidationSchema = Yup.object({
    gwgInfo: Yup.object({
        extendedSoWQuestions: Yup.boolean(),
        customerRelationIncomingFlow: Yup.array()
            .min(1, 'Required')
            .of(Yup.string()),
        generateIncomingFlow: Yup.array().min(1, 'Required').of(Yup.string()),
        ownerSourceOfFund: Yup.string()
            .nullable()
            .when('extendedSoWQuestions', {
                is: extendedSoWQuestions => !!extendedSoWQuestions,
                then: Yup.string().nullable().required('Required'),
            }),
        investedAmount: Yup.string()
            .nullable()
            .when('extendedSoWQuestions', {
                is: extendedSoWQuestions => !!extendedSoWQuestions,
                then: Yup.string().nullable().required('Required'),
            }),
        privateInvestmentSource: Yup.array()
            .nullable()
            .of(Yup.string())
            .when('ownerSourceOfFund', {
                is: ownerSourceOfFund =>
                    ownerSourceOfFund === 'private_investment_gain',
                then: Yup.array()
                    .of(Yup.string())
                    .min(1, 'Required')
                    .required('Required'),
            }),
    }),
    file: Yup.array().of(
        Yup.mixed().test(
            'isFile',
            'Please upload a valid file',
            (files: File[]) => files.length === 0 || files[0] instanceof File,
        ),
    ),
});
export type SourceOfWealthFormValues = ReturnType<
    typeof sourceOfWealthValidationSchema.validateSync
>;

export function oneOfEnum<T>(enumObject: {[s: string]: T} | ArrayLike<T>) {
    return Yup.mixed<T>().oneOf(Object.values(enumObject));
}

export const MISSING_PAYMENT_CUSTOMER_ERROR_KEY = 'REQUIRED_PAYMENT_CUSTOMER';
export const incomingAndOutgoingPaymentsValidationSchema = Yup.object({
    gwgInfo: Yup.object({
        type: oneOfEnum<IcomingAndOutgoingPayments>(
            INCOMING_AND_OUTGOING_PAYMENTS_STEPS,
        ).required('Required'),
        outgoingPaymentCustomers: Yup.array().when('type', {
            is: type => type === StepTypes.OUTGOING_PAYMENTS,
            then: Yup.array()
                .of(
                    Yup.object({
                        uniqueId: Yup.string(),
                        name: Yup.string(),
                        country: Yup.string()
                            .nullable()
                            .when('name', {
                                is: name => !!name,
                                then: Yup.string()
                                    .nullable()
                                    .required('Required'),
                            }),
                        website: Yup.string().nullable(),
                    }),
                )
                .test({
                    name: 'at-least-one',
                    message: MISSING_PAYMENT_CUSTOMER_ERROR_KEY,
                    test: val => val.some((v: any) => !!v.name && !!v.country),
                }),
        }),
        incomingPaymentCustomers: Yup.array().when('type', {
            is: type => type === StepTypes.INCOMING_PAYMENTS,
            then: Yup.array()
                .of(
                    Yup.object({
                        uniqueId: Yup.string(),
                        name: Yup.string(),
                        country: Yup.string()
                            .nullable()
                            .when('name', {
                                is: name => !!name,
                                then: Yup.string()
                                    .nullable()
                                    .required('Required'),
                            }),
                        website: Yup.string().nullable(),
                    }),
                )
                .test({
                    name: 'at-least-one',
                    message: MISSING_PAYMENT_CUSTOMER_ERROR_KEY,
                    test: val => val.some((v: any) => !!v.name && !!v.country),
                }),
        }),
    }),
});

// TODO -> infer from schema after package upgrade
export type IncomingAndOutgoingPaymentsFormValues = {
    onlyCountries: (string | null | undefined)[];
    gwgInfo: {
        type: IcomingAndOutgoingPayments;
        outgoingPaymentCustomers?: {
            uniqueId: string;
            name: string;
            website: string;
            country: string;
        }[];
        incomingPaymentCustomers?: {
            uniqueId: string;
            name: string;
            website: string;
            country: string;
        }[];
    };
};
