const Sync = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M4.327 19.673v-1.5H7.25l-.727-.696c-.822-.76-1.406-1.611-1.753-2.553a8.204 8.204 0 0 1-.52-2.855c0-1.728.506-3.278 1.518-4.649C6.78 6.05 8.108 5.108 9.75 4.596v1.581a6.14 6.14 0 0 0-2.905 2.318A6.216 6.216 0 0 0 5.75 12.07c0 .782.148 1.542.444 2.279.296.737.757 1.419 1.383 2.044l.634.635v-2.739h1.5v5.385H4.327Zm9.923-.27v-1.58a6.14 6.14 0 0 0 2.905-2.318 6.216 6.216 0 0 0 1.095-3.574c0-.782-.148-1.542-.444-2.279-.296-.737-.757-1.419-1.383-2.044l-.634-.635v2.739h-1.5V4.327h5.384v1.5H16.75l.727.696c.791.791 1.368 1.65 1.73 2.576.362.926.543 1.87.543 2.832 0 1.728-.506 3.278-1.518 4.649-1.012 1.37-2.34 2.312-3.982 2.824Z"
        />
    </svg>
);

export default Sync;
