import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';

import {smsValidationSchema} from 'form/validation/login';
import {obfuscatePhone, parseJwt} from 'helpers/common';
import {useAuth} from 'context/auth-context';
import NextStepButton from 'components/pages/OnboardingPage/components/NextStepButton';

import {useCountdown} from 'hooks/common';
import Button from 'components/ui/Button';
import PinInput from 'components/ui/Form/PinInput/PinInput';
import usePinInput from 'components/ui/Form/PinInput/usePinInput';
import Form, {WithFormError} from '../Form/Form/Form';

import styles from './ConfirmationMobile.module.scss';
import {TwoFASuccessHandler} from 'components/pages/TwoFAPage/useTwoFAStore';
import Alert from 'components/ui/Alert/Alert';

export interface IConfirmationMobileProps {
    onSuccess: (data: TwoFASuccessHandler) => void;
    onResend: () => void;
    isVerifying: boolean;
    isResending: boolean;
    error?: string;
    setError: (error?: string) => void;
}

const RESEND_SMS_AFTER_SECONDS = 60;

export interface IConfirmationMobileFormValues {
    pin: string;
}

const ConfirmationMobile = ({
    onSuccess,
    isVerifying,
    isResending,
    onResend,
    error,
    setError,
}: IConfirmationMobileProps) => {
    const {token, user} = useAuth();
    const [verifyErrorCount, setVerifyErrorCount] = useState<number>(0);
    const {startCounter, timeLeft, stopCounter, restartCounter} = useCountdown(
        RESEND_SMS_AFTER_SECONDS,
        false,
    );

    useEffect(() => {
        startCounter?.();
    }, []);

    const pinMethods = usePinInput(6);

    const formMethods = useForm<WithFormError<IConfirmationMobileFormValues>>({
        resolver: yupResolver(smsValidationSchema),
    });

    const {watch, handleSubmit, reset} = formMethods;
    const pin = watch('pin');

    const decodedToken = parseJwt(token);

    useEffect(() => {
        return () => stopCounter?.();
    }, [stopCounter]);

    const getNewSms = () => {
        reset({}, {});
        pinMethods.resetPinInput();
        setVerifyErrorCount(0);
        restartCounter?.();
        onResend();
    };

    useEffect(() => {
        if (pin?.length === 6 && !isVerifying) {
            handleSubmit(values =>
                onSuccess({passCode: values.pin, token, user: user?.['@id']}),
            )();
        }
    }, [handleSubmit, onSuccess, pin]);

    return (
        <Form<IConfirmationMobileFormValues>
            formMethods={formMethods}
            onSubmit={values =>
                onSuccess({passCode: values.pin, token, user: user?.['@id']})
            }
            onControlValueChange={() => (error ? setError() : undefined)}
            className={styles.form}
        >
            <>
                <p className={styles.title}>Enter Verification Code</p>
                <p className={styles.text}>
                    We’ve sent you a text with the confirmation code to your
                    phone &nbsp;
                    <var data-var="phone-number">
                        {user?.phone
                            ? obfuscatePhone(user.phone)
                            : decodedToken?.phoneNumber}
                    </var>
                </p>
            </>
            <PinInput methods={pinMethods} />

            <div className={styles.buttonRow}>
                {error ? <Alert variant="danger" message={error} /> : null}
                {timeLeft === 0 ? (
                    <Button
                        className={styles.button}
                        onClick={getNewSms}
                        isLoading={isResending}
                    >
                        Get New SMS
                    </Button>
                ) : (
                    <>
                        <NextStepButton loading={isVerifying} />
                        <p className={styles.countDown}>
                            Code didn&apos;t arrive? You can retry in&nbsp;
                            <var data-var="timeleft">
                                00:{timeLeft < 10 ? `0${timeLeft}` : timeLeft}
                            </var>
                        </p>
                    </>
                )}
            </div>
        </Form>
    );
};

export default ConfirmationMobile;
