import {useQuery} from '@tanstack/react-query';
import {getToday} from 'helpers/dates';
import {useClient} from 'context/auth-context';

const useGetHolidays = (currencies?: string[]) => {
    const client = useClient();
    const after = getToday();

    return useQuery({
        queryKey: ['holidays', {...currencies, after}],
        queryFn: () =>
            client('/web_api/holidays', {
                params: {
                    'date[after]': after,
                    currency: currencies,
                },
            }).then(response =>
                (response as any).map((holiday: any) => holiday.date),
            ),
        enabled: !!currencies?.length,
    });
};

export {useGetHolidays};
