const MoreVertical = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M12 19.27c-.412 0-.766-.147-1.06-.441a1.444 1.444 0 0 1-.44-1.06c0-.412.147-.765.44-1.059a1.45 1.45 0 0 1 1.06-.44c.412 0 .766.147 1.06.44.293.294.44.647.44 1.06 0 .412-.147.765-.44 1.059-.294.294-.648.44-1.06.44Zm0-5.77c-.412 0-.766-.146-1.06-.44A1.445 1.445 0 0 1 10.5 12c0-.412.147-.765.44-1.059.294-.294.648-.44 1.06-.44.412 0 .766.146 1.06.44.293.294.44.647.44 1.06 0 .412-.147.765-.44 1.059a1.45 1.45 0 0 1-1.06.44Zm0-5.769a1.45 1.45 0 0 1-1.06-.44 1.444 1.444 0 0 1-.44-1.06c0-.412.147-.765.44-1.06a1.45 1.45 0 0 1 1.06-.44c.412 0 .766.147 1.06.44.293.295.44.648.44 1.06 0 .413-.147.766-.44 1.06a1.45 1.45 0 0 1-1.06.44Z"
        />
    </svg>
);

export default MoreVertical;
