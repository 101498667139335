const Disconnect = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M20.777 22.916 1.265 3.404l1.07-1.069 19.511 19.512-1.07 1.069ZM11 18.75a3.614 3.614 0 0 1-2.652-1.098A3.614 3.614 0 0 1 7.25 15c0-1.035.366-1.92 1.098-2.652A3.614 3.614 0 0 1 11 11.25h.25l1.5 1.5H11c-.62 0-1.15.22-1.59.66-.44.44-.66.97-.66 1.59 0 .621.22 1.151.66 1.591.44.44.97.66 1.59.66h6.25l3.117 3.117a3.516 3.516 0 0 1-.65.277 2.42 2.42 0 0 1-.717.105c-.625 0-1.18-.187-1.668-.562a2.62 2.62 0 0 1-.965-1.438H11Zm10.623.043-3.415-3.415c.124-.038.25-.068.38-.092.13-.024.267-.035.412-.035a2.65 2.65 0 0 1 1.947.803A2.65 2.65 0 0 1 21.75 18c0 .144-.012.281-.036.411a3.64 3.64 0 0 1-.091.38Zm-6.615-6.615-1.11-1.11A2.243 2.243 0 0 0 15.25 9c0-.62-.22-1.15-.66-1.59-.44-.44-.97-.66-1.59-.66H9.58l-1.5-1.5H13c1.036 0 1.92.366 2.652 1.098A3.614 3.614 0 0 1 16.75 9c0 .668-.16 1.281-.48 1.839a3.834 3.834 0 0 1-1.262 1.338ZM5 8.75a2.65 2.65 0 0 1-1.947-.803A2.65 2.65 0 0 1 2.25 6c0-.5.127-.96.38-1.378a2.812 2.812 0 0 1 1.003-.989l3.744 3.744a2.754 2.754 0 0 1-.998.997 2.663 2.663 0 0 1-1.38.376Z"
        />
    </svg>
);

export default Disconnect;
