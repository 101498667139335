import {saveAs} from 'file-saver';

interface IArgs {
    fileName: string;
    content: string;
    contentType?: string;
}
const saveFile = async ({content, fileName, contentType}: IArgs) => {
    if (!content || !fileName) return;

    const extension = fileName.split('.').pop();
    const base64Response = await fetch(
        `data:application/${contentType ?? extension?.toLowerCase()};base64, ${content}`,
    );
    const blob = await base64Response.blob();
    return saveAs(blob, fileName);
};

export {saveFile};
