const TimeBased = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            fill-rule="evenodd"
            d="M17.098 4.614a8.504 8.504 0 0 1 2.65 2.982v-2H21v4.841h-4.787v-1.26h2.905c-.496-1.36-1.356-2.497-2.59-3.412C15.177 4.76 13.67 4.26 12 4.26c-.948 0-1.858.172-2.735.515A7.693 7.693 0 0 0 6.91 6.227a7.785 7.785 0 0 0-1.72 2.26c-.45.876-.72 1.86-.806 2.956l-.022.292H3.104l.03-.343c.106-1.245.414-2.384.927-3.415a9.103 9.103 0 0 1 1.968-2.654 8.66 8.66 0 0 1 2.74-1.718A8.757 8.757 0 0 1 12 3c1.863 0 3.565.539 5.098 1.613Zm3.79 7.652h-1.247l-.024.29a7.958 7.958 0 0 1-.776 2.875 7.795 7.795 0 0 1-1.667 2.28A7.508 7.508 0 0 1 12 19.741c-1.641 0-3.13-.487-4.476-1.462a7.38 7.38 0 0 1-2.636-3.456h2.9v-1.26H3v4.84h1.252v-1.987a8.96 8.96 0 0 0 2.647 2.98C8.392 20.466 10.095 21 12 21a8.637 8.637 0 0 0 3.336-.65 8.743 8.743 0 0 0 2.75-1.789 9.325 9.325 0 0 0 1.921-2.663 8.764 8.764 0 0 0 .86-3.295l.022-.337Zm-8.5-4.588h-1.286v4.552l3.58 4.463.996-.82-3.29-4.102V7.678Z"
            clip-rule="evenodd"
        />
    </svg>
);

export default TimeBased;
