import {useEffect, useState} from 'react';
import {useAuth} from 'context/auth-context';
import {useGetAuthFactors, useGetOnboardingTwoFA} from 'api/futurae';
import {translate} from 'helpers/localize';
import {parseJwt} from 'helpers/common';
import {IDefaultStepProps} from '../useOnboarding';
import StepTitle from '../components/StepTitle';

import styles from './Steps.module.scss';
import Alert from 'components/ui/Alert/Alert';
import AmnisLoader from 'components/ui/AmnisLoader/AmnisLoader';
import EnrollMobile from 'components/ui/ConfirmationMobile/EnrollMobile';
import AmnisButton from 'components/ui/AmnisButton/AmnisButton';
import {useTwoFAStore} from 'components/pages/TwoFAPage/useTwoFAStore';

const TwoFactor = (props: IDefaultStepProps) => {
    const {onSuccess} = props;
    const {token} = useAuth();
    const [error, setError] = useState<string>('');
    const isTwoFAOpen = useTwoFAStore(state => state.isOpen);

    const {
        data: authFactors,
        isLoading: isLoadingAuthFactors,
        error: authFactorsError,
    } = useGetAuthFactors(token);

    const {
        mutate: triggerTwoFA,
        data: onboardingTwoFA,
        isPending: isTwoFALoading,
    } = useGetOnboardingTwoFA();

    useEffect(() => {
        if (authFactors?.factors?.includes('sms') && !onboardingTwoFA) {
            triggerTwoFA(
                {},
                {
                    onSuccess: () => onSuccess(),
                    onError: (err: any) => {
                        setError(
                            err?.['hydra:description'] ||
                                err?.status_msg ||
                                'Something went wrong',
                        );
                    },
                },
            );
        }
    }, [authFactors]);

    if (isLoadingAuthFactors) {
        return <AmnisLoader />;
    }
    const hasSms2faSetup = authFactors?.factors?.includes('sms');

    const render2FA = () => {
        if (!authFactors?.factors?.includes('sms')) {
            return <EnrollMobile onSuccess={onSuccess} />;
        }

        if (!isTwoFAOpen) {
            return (
                <AmnisButton onClick={triggerTwoFA}>Request SMS</AmnisButton>
            );
        }

        return null;
    };

    return (
        <div className={styles.form}>
            <StepTitle
                title={
                    hasSms2faSetup
                        ? 'Enter security code'
                        : 'Enhance your security - enable 2FA'
                }
                subTitle={
                    hasSms2faSetup
                        ? translate(
                              'Please enter the security code that we have just sent via SMS to the following telephone number: %{phoneNumber}',
                              {
                                  phoneNumber: parseJwt(token)?.phoneNumber,
                              },
                          )
                        : 'In order to be able to confirm your identity in the next step, we will send you a security code via SMS. Is the phone number below your number and can you receive SMS on this phone? If not, please change the number.'
                }
            />
            {authFactorsError || error ? (
                <Alert
                    variant="danger"
                    message={
                        (authFactorsError as any)?.['hydra:description'] ||
                        error
                    }
                />
            ) : (
                render2FA()
            )}
        </div>
    );
};

export default TwoFactor;
