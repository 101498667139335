const DisableSync = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="m19.608 22.062-3.514-3.514a8.792 8.792 0 0 1-.882.481 7.499 7.499 0 0 1-.962.375v-1.58c.132-.053.26-.11.382-.17.122-.062.246-.127.372-.196L6.552 9.006c-.254.462-.451.95-.592 1.466a6.054 6.054 0 0 0-.21 1.598c0 .782.148 1.541.444 2.278.296.738.757 1.419 1.383 2.045l.634.634V14.29h1.5v5.384H4.327v-1.5H7.25l-.727-.696c-.791-.79-1.368-1.65-1.73-2.576a7.716 7.716 0 0 1-.543-2.831c0-.763.102-1.494.307-2.192a7.676 7.676 0 0 1 .895-1.972L1.938 4.393l1.07-1.07 17.669 17.67-1.07 1.069Zm-1.02-6.008-1.115-1.115a6.42 6.42 0 0 0 .574-1.438c.135-.507.203-1.03.203-1.57 0-.782-.148-1.541-.444-2.279-.296-.737-.757-1.418-1.383-2.044l-.634-.635v2.739h-1.5V4.327h5.384v1.5H16.75l.727.696c.791.792 1.368 1.65 1.73 2.576a7.716 7.716 0 0 1 .236 4.998 8.676 8.676 0 0 1-.855 1.957ZM9.053 6.518 7.946 5.412c.285-.177.574-.33.87-.46.294-.13.606-.249.934-.355v1.58l-.359.158a2.77 2.77 0 0 0-.339.183Z"
        />
    </svg>
);

export default DisableSync;
