import {useBusinessIndustries} from 'api/business-industries';
import {
    useGetBusinessActivities,
    useUpdateBusinessPartnerOnboarding,
    useUploadBusinessPartnerDocument,
} from 'api/business-partner';
import {useCountries} from 'api/countries';
import {useParameters} from 'api/parameters';
import RestrictedBusinessAlert from 'components/pages/OnboardingPage/components/RestrictedBusinessAlert';
import AutoComplete from 'components/ui/Form/AutoComplete/AutoComplete';
import CountryAutoComplete from 'components/ui/Form/AutoComplete/CountryAutoComplete';
import Form, {FormOnSubmit, WithFormError} from 'components/ui/Form/Form/Form';
import {
    BusinessActivitiesCompanyFormValues,
    businessActivitiesValidationSchema,
} from 'form/validation/businessPartner';
import {handleTypedFormError} from 'helpers/handleFormError';
import {translate, translateDropdownOptions} from 'helpers/localize';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import CheckBoxGroup from 'components/ui/Form/OnboardingCheckBoxGroup/OnboardingCheckBoxGroup';
import {yupResolver} from '@hookform/resolvers';
import FileUploadArea from 'components/ui/Form/FileUploadArea/FileUploadArea';
import useFileUploadStatuses, {
    isNewFileUploadStatus,
} from 'components/ui/Form/FileUploadButton/useFileUploadStatuses';
import {findOnboardingStepByNumber} from 'components/pages/OnboardingPage/onboardingSteps';
import {physicalPresenceData} from 'constants/businessPartnerAttributes';
import {SOURCE_OF_FUNDS, SOURCE_OF_REFFERAL} from 'constants/common';
import TextInput from 'components/ui/Form/TextInput/TextInput';
import {LegalType} from 'types/onboarding';
import NextStepButton from '../components/NextStepButton';
import StepTitle from '../components/StepTitle';
import {IDefaultStepProps} from '../useOnboarding';

import styles from './Steps.module.scss';

export const convertToTranslatedOptions = (options: {}) =>
    Object.keys(options).map(key => ({
        '@id': key.split('-')[0],
        label: translate(options[key as keyof typeof options]),
    }));

const DEFAULT_FORM_VALUES: BusinessActivitiesCompanyFormValues = {
    gwgInfo: {
        businessIndustry: '',
        numberEmployees: '',
        countryOfBusiness: [],
        businessActivities: [],
        physicalPresence: [],
        isCompanyOnAmnis: '0',
        isGroup: '0',
        ownerOfCompany: '0',
        isIndividual: false,
        sourceOfFunds: '',
        customSourceOfFunds: '',
    },
    companyOrgChartFiles: [],
    sourceOfFundsFiles: [],
    website: '',
    inReview: false,
    referralType: null,
};

const BusinessActivities = (props: IDefaultStepProps) => {
    const {
        legalType,
        isIndividual,
        activeBusinessPartner,
        setActiveBusinessPartner,
        onSuccess,
        inReview,
        currentStep,
        bpDocuments,
    } = props;
    const [defaultValues, setDefaultValues] =
        useState<Partial<BusinessActivitiesCompanyFormValues>>(
            DEFAULT_FORM_VALUES,
        );

    const formMethods = useForm<
        WithFormError<BusinessActivitiesCompanyFormValues>
    >({
        defaultValues: {
            ...defaultValues,
            inReview,
            gwgInfo: {
                ...defaultValues.gwgInfo,
                isIndividual,
            },
        },
        resolver: yupResolver(businessActivitiesValidationSchema),
    });
    const {watch, register} = formMethods;
    register('gwgInfo.isIndividual');
    register('inReview');
    const {mutate: uploadBpDocument} = useUploadBusinessPartnerDocument();
    const {updateStatus, uploadStatuses} = useFileUploadStatuses();

    const {countries, isLoading: countriesIsLoading} = useCountries();
    const {data, isLoading: parametersIsLoading} = useParameters();
    const {data: businessIndustries, isLoading: businessIndustriesIsLoading} =
        useBusinessIndustries();
    const {
        data: allBusinessActivities,
        isLoading: isBusinessActivitiesLoading,
    } = useGetBusinessActivities();

    const {mutate: addAdditionalInfo, isPending: isLoading} =
        useUpdateBusinessPartnerOnboarding();

    const employees = data?.find(
        parameter => parameter.name === 'numberEmployeesList',
    )?.value ?? [{}];
    const employeesTranslated = convertToTranslatedOptions(employees);
    const isGroupValue = watch('gwgInfo.isGroup');
    const isOwnerOfCompanyValue = watch('gwgInfo.ownerOfCompany');
    const isCompanyOnAmnisValue = watch('gwgInfo.isCompanyOnAmnis');
    const sourceOfFundsValue = watch('gwgInfo.sourceOfFunds');
    const companyOrgChartDocuments = formMethods.watch('companyOrgChartFiles');
    const sourceOfFundsDocuments = formMethods.watch('sourceOfFundsFiles');
    const uploading = useRef<boolean>(false);

    const handleUploadFile = useCallback(
        (files: File[], category: string) => {
            if (uploading.current === true) return;
            uploading.current = true;
            const needToUpload = files.filter(
                doc =>
                    uploadStatuses.find(
                        status =>
                            isNewFileUploadStatus(status) &&
                            status.document === doc,
                    ) === undefined,
            );
            if (!needToUpload.length) return;
            updateStatus({
                document: needToUpload[0],
                status: 'loading',
                name: needToUpload[0].name,
            });
            uploadBpDocument(
                {
                    activeBpId: activeBusinessPartner['@id'],
                    files,
                    type: 'onboarding',
                    category,
                },
                {
                    onSuccess: data => {
                        formMethods.setValue(
                            'file',
                            files.filter(d => d !== files[0]),
                        );
                        updateStatus({
                            document: needToUpload[0],
                            name: needToUpload[0].name,
                            status: 'uploaded',
                        });
                    },
                    onError: (errorResponse: any) => {
                        updateStatus({
                            document: needToUpload[0],
                            status: 'error',
                            name: needToUpload[0].name,
                            errorMessage:
                                errorResponse['hydra:description'] ||
                                'Something went wrong',
                        });
                    },
                    onSettled: () => {
                        uploading.current = false;
                    },
                },
            );
        },
        [
            activeBusinessPartner,
            formMethods,
            updateStatus,
            uploadBpDocument,
            uploadStatuses,
        ],
    );

    useEffect(() => {
        if (companyOrgChartDocuments?.length) {
            handleUploadFile(companyOrgChartDocuments, 'company_org_chart');
        }

        if (sourceOfFundsDocuments?.length) {
            handleUploadFile(sourceOfFundsDocuments, 'private_source_of_funds');
        }
    }, [companyOrgChartDocuments, sourceOfFundsDocuments, handleUploadFile]);

    const onSubmit: FormOnSubmit<BusinessActivitiesCompanyFormValues> = (
        values,
        setError,
    ) => {
        // TODO - fix after yup upgrade
        // Typescript can't ignore a block, it's either one line or an entire file...
        const {
            gwgInfo: {
                //@ts-ignore
                businessIndustry,

                //@ts-ignore
                numberEmployees,

                //@ts-ignore
                countryOfBusiness,

                //@ts-ignore
                isGroup,

                //@ts-ignore
                businessActivities,

                //@ts-ignore
                physicalPresence,

                //@ts-ignore
                ownerOfCompany,

                //@ts-ignore
                isCompanyOnAmnis,

                //@ts-ignore
                sourceOfFunds,

                //@ts-ignore
                customSourceOfFunds,
            },
            inReview: _inRewiew,
            ...rest
        } = values;

        const data = isIndividual
            ? {
                  ...rest,
                  gwgInfo: {
                      id: activeBusinessPartner?.gwgInfo?.id,
                      countryOfBusiness,
                      businessActivities,
                      physicalPresence,
                      ownerOfCompany: ownerOfCompany === '1',
                      isCompanyOnAmnis: isCompanyOnAmnis === '1',
                      businessIndustry,
                      sourceOfFunds:
                          sourceOfFunds === 'OTHERS'
                              ? customSourceOfFunds
                              : sourceOfFunds,
                  },
                  businessPartnerId: activeBusinessPartner['@id'],
                  step: findOnboardingStepByNumber(currentStep)?.type,
              }
            : {
                  ...rest,
                  gwgInfo: {
                      id: activeBusinessPartner?.gwgInfo?.id,
                      businessIndustry,
                      numberEmployees,
                      countryOfBusiness,
                      isGroup: isGroup ? +isGroup === 1 : false,
                      businessActivities,
                      physicalPresence,
                  },
                  businessPartnerId: activeBusinessPartner['@id'],
                  step: findOnboardingStepByNumber(currentStep)?.type,
              };

        addAdditionalInfo(data, {
            onSuccess: response => {
                setActiveBusinessPartner(response);
                onSuccess({refetchCrpList: true});
            },
            onError: (requestErrors: any) => {
                handleTypedFormError(requestErrors, setError, values);
            },
        });
    };

    useEffect(() => {
        const {gwgInfo, referralType} = activeBusinessPartner;
        let sourceOfFunds: string | undefined;
        if (gwgInfo?.sourceOfFunds) {
            const defaultSource = SOURCE_OF_FUNDS[
                legalType ?? LegalType.INDIVIDUAL
            ].find(source => source['@id'] === gwgInfo?.sourceOfFunds)?.['@id'];
            if (defaultSource) {
                sourceOfFunds = defaultSource;
            } else {
                sourceOfFunds = 'OTHERS';
            }
        }
        setDefaultValues({
            gwgInfo: {
                businessIndustry: gwgInfo?.businessIndustry || null,
                numberEmployees: !isIndividual
                    ? gwgInfo?.numberEmployees || null
                    : '',
                countryOfBusiness: gwgInfo?.countryOfBusiness ?? [],
                isGroup: gwgInfo?.isGroup ? '1' : '0',
                businessActivities: gwgInfo?.businessActivities ?? [],
                physicalPresence: gwgInfo?.physicalPresence || null,
                ownerOfCompany: gwgInfo?.ownerOfCompany ? '1' : '0',
                isCompanyOnAmnis: gwgInfo?.isCompanyOnAmnis ? '1' : '0',
                isIndividual,
                sourceOfFunds: sourceOfFunds || null,
                customSourceOfFunds: gwgInfo?.sourceOfFunds || null,
            },
            inReview,
            referralType,
            website: activeBusinessPartner.website,
        });
    }, [activeBusinessPartner, inReview, isIndividual, legalType]);

    return (
        <>
            <StepTitle
                title={
                    inReview
                        ? 'Are the details about your business activities up to date?'
                        : 'Tell us more about your business activities'
                }
                subTitle="We need these details to comply with the anti money laundering regulations"
            />
            <Form<BusinessActivitiesCompanyFormValues>
                onSubmit={onSubmit}
                className={styles.form}
                formMethods={formMethods}
                defaultValues={defaultValues}
                confirmLeave
            >
                {isIndividual ? (
                    <>
                        <CheckBoxGroup
                            name="gwgInfo.ownerOfCompany"
                            options={[
                                {label: 'No', value: '0'},
                                {label: 'Yes', value: '1'},
                            ]}
                            label="Are you an owner of a company?"
                            type="radio"
                            variant="dark"
                            size="large"
                        />
                    </>
                ) : (
                    <>
                        <RestrictedBusinessAlert />
                        <CountryAutoComplete
                            label="Please select all countries where your company has business relationships (offices, suppliers, clients)"
                            name="gwgInfo.countryOfBusiness"
                            placeholder="Please select"
                            isLoading={countriesIsLoading}
                            options={countries}
                            variant="dark"
                            isMulti
                            showMultiMessage
                            size="large"
                        />
                        <CheckBoxGroup
                            name="gwgInfo.isGroup"
                            options={[
                                {label: 'No', value: '0'},
                                {label: 'Yes', value: '1'},
                            ]}
                            label="Does your company belong to a group structure?"
                            type="radio"
                            variant="dark"
                            size="large"
                        />
                        {isGroupValue && +isGroupValue === 1 ? (
                            <FileUploadArea
                                uploadStatuses={uploadStatuses}
                                name="companyOrgChartFiles"
                                label="Please upload your group structure as file (.pdf/.png/.jpg)"
                                info="The group structure overview should contain ownership ratios in % and ratios up to the effective beneficial owner (private person)."
                                uploadedDocuments={
                                    bpDocuments
                                        ?.filter(
                                            doc =>
                                                doc.category ===
                                                'company_org_chart',
                                        )
                                        .map(doc => ({
                                            id: doc['@id'],
                                            name: doc.originalFileName,
                                        })) || []
                                }
                            />
                        ) : null}
                        <AutoComplete
                            label="How many employees does your company have?"
                            name="gwgInfo.numberEmployees"
                            placeholder="Please select"
                            isLoading={parametersIsLoading}
                            options={employeesTranslated}
                            sortKey="@id"
                            numericSort
                            variant="dark"
                            translateInputContent={false}
                            size="large"
                        />
                        <AutoComplete
                            label="Which of the following does describe the activities of your company?"
                            name="gwgInfo.businessActivities"
                            placeholder="Please select"
                            isLoading={isBusinessActivitiesLoading}
                            options={allBusinessActivities || []}
                            labelKey="value"
                            sortKey="@id"
                            numericSort
                            variant="dark"
                            size="large"
                            isMulti
                            showMultiMessage
                        />
                        <AutoComplete
                            label="Please provide information about your company's physical presence"
                            name="gwgInfo.physicalPresence"
                            placeholder="Please select"
                            options={physicalPresenceData}
                            sortKey="@id"
                            numericSort
                            variant="dark"
                            size="large"
                            isMulti
                            showMultiMessage
                        />
                    </>
                )}
                {isOwnerOfCompanyValue === '1' ? (
                    <CheckBoxGroup
                        name="gwgInfo.isCompanyOnAmnis"
                        options={[
                            {label: 'No', value: '0'},
                            {label: 'Yes', value: '1'},
                        ]}
                        label="Is your company already an amnis client?"
                        type="radio"
                        variant="dark"
                        size="large"
                    />
                ) : null}
                {isOwnerOfCompanyValue === '1' &&
                isCompanyOnAmnisValue === '0' ? (
                    <AutoComplete
                        label="What industry is your company in?"
                        info="If you can't find the exact industry type, please choose the closest option."
                        name="gwgInfo.businessIndustry"
                        placeholder="Please select"
                        isLoading={businessIndustriesIsLoading}
                        labelKey="value"
                        valueKey="@id"
                        options={translateDropdownOptions(
                            businessIndustries || [],
                            'value',
                        )}
                        variant="dark"
                        sortKey="label"
                        size="large"
                    />
                ) : null}
                {isIndividual && isOwnerOfCompanyValue === '0' ? (
                    <AutoComplete
                        label="In which industry do you work?"
                        info="If you can't find the exact industry type, please choose the closest option."
                        name="gwgInfo.businessIndustry"
                        placeholder="Please select"
                        isLoading={businessIndustriesIsLoading}
                        labelKey="value"
                        valueKey="@id"
                        options={translateDropdownOptions(
                            businessIndustries || [],
                            'value',
                        )}
                        variant="dark"
                        sortKey="label"
                        size="large"
                    />
                ) : null}
                {isIndividual ? (
                    <>
                        <AutoComplete
                            label="Source of funds"
                            name="gwgInfo.sourceOfFunds"
                            placeholder="Please select"
                            options={SOURCE_OF_FUNDS[legalType!]}
                            variant="dark"
                            info="Refers to the origin of the funds which are the subject of the transactions."
                            size="large"
                        />
                        {isCompanyOnAmnisValue === '0' ? (
                            <FileUploadArea
                                uploadStatuses={uploadStatuses}
                                name="sourceOfFundsFiles"
                                label="Please upload a proof of source of funds as a file (.pdf/.png/.jpg)"
                                uploadedDocuments={bpDocuments
                                    ?.filter(
                                        doc =>
                                            doc.category ===
                                            'private_source_of_funds',
                                    )
                                    .map(doc => ({
                                        id: doc['@id'],
                                        name: doc.originalFileName,
                                    }))}
                            />
                        ) : null}
                        {sourceOfFundsValue === 'OTHERS' ? (
                            <TextInput
                                label="Please specify"
                                name="gwgInfo.customSourceOfFunds"
                                variant="dark"
                                size="large"
                            />
                        ) : null}
                    </>
                ) : null}
                {isIndividual && !inReview ? (
                    <AutoComplete
                        label="Where did you hear from us?"
                        name="referralType"
                        placeholder="Please select"
                        options={SOURCE_OF_REFFERAL.filter(
                            ref => ref['@id'] !== 'Clientreferral',
                        )}
                        variant="dark"
                        size="large"
                        isClearable
                    />
                ) : null}

                <NextStepButton loading={isLoading} />
            </Form>
        </>
    );
};

export default BusinessActivities;
