import {useQuery} from '@tanstack/react-query';
import {translate} from 'helpers/localize';
import {useClient} from 'context/auth-context';
import {LEGAL_FORM} from 'constants/cache';

export interface ILegalForm {
    '@context': '/web_api/contexts/LegalForm';
    '@type': 'LegalForm';
    '@id': string;
    value: string;
}

function useLegalForms() {
    const client = useClient();

    const result = useQuery<ILegalForm[]>({
        queryKey: ['legal-forms'],
        queryFn: () =>
            client<ILegalForm[]>('/web_api/legal_forms?order[position]=ASC', {})
                // fake the ID, API needs to be changed
                .then(legalForms =>
                    legalForms.map((legalForm: any) => ({
                        ...legalForm,
                        value: legalForm['@id'],
                        label: translate(legalForm.value),
                    })),
                ),
    });

    return {...result, legalForms: result.data ?? []};
}

const useLegalForm = (legalFormId?: string | null) => {
    const authenticatedClient = useClient();

    return useQuery<ILegalForm>({
        queryKey: [LEGAL_FORM, {id: legalFormId}],
        queryFn: () => authenticatedClient(legalFormId!, {}),
        enabled: !!legalFormId,
    });
};

export {useLegalForms, useLegalForm};
