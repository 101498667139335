const AddUser = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M18.125 13.75v-3h-3v-1.5h3v-3h1.5v3h3v1.5h-3v3h-1.5ZM9 11.693c-.963 0-1.786-.343-2.472-1.028C5.843 9.979 5.5 9.155 5.5 8.193c0-.963.343-1.787 1.028-2.472.686-.686 1.51-1.028 2.472-1.028s1.786.342 2.472 1.028c.685.685 1.028 1.51 1.028 2.472s-.343 1.786-1.028 2.472c-.686.685-1.51 1.028-2.472 1.028Zm-7.5 7.615v-2.223c0-.49.133-.943.399-1.36a2.664 2.664 0 0 1 1.066-.963 14.507 14.507 0 0 1 2.992-1.09 12.95 12.95 0 0 1 6.086 0c1.006.242 2.003.605 2.992 1.09.444.224.8.545 1.066.963.266.417.399.87.399 1.36v2.223h-15Zm1.5-1.5h12v-.723a.98.98 0 0 0-.176-.563 1.333 1.333 0 0 0-.478-.422 12.854 12.854 0 0 0-2.635-.964 11.39 11.39 0 0 0-5.422 0c-.895.219-1.774.54-2.635.964-.201.11-.36.25-.478.422a.98.98 0 0 0-.176.563v.723Zm6-7.615c.55 0 1.02-.196 1.412-.588.392-.391.588-.862.588-1.412 0-.55-.196-1.021-.588-1.413A1.926 1.926 0 0 0 9 6.193c-.55 0-1.02.196-1.413.587A1.926 1.926 0 0 0 7 8.193c0 .55.196 1.02.587 1.412.392.392.863.588 1.413.588Z"
        />
    </svg>
);

export default AddUser;
