import {ADMIN_LANGUAGES} from 'constants/languages';
import {useLanguage} from 'context/language-context';

const getAvailableLocalizeLanguages = () => {
    let localizeLanguages = [
        {id: '/web_api/languages/2', code: 'de', name: 'Deutsch'},
        {id: '/web_api/languages/1', code: 'en', name: 'English'},
    ];

    (window as any).Localize.getAvailableLanguages(
        (err: any, languages: any) => {
            localizeLanguages = languages;
        },
    );

    const languageCodes = localizeLanguages.map(lang => lang.code);

    return ADMIN_LANGUAGES.filter(language =>
        languageCodes.includes(language.code),
    );
};

const getLocalizeLanguage = () => (window as any).Localize.getLanguage();

const setLocalizeLanguage = (language: string) => {
    (window as any).Localize.setLanguage(language);
};

const prefetchLocalizeLanguage = (language: string | string[]) => {
    (window as any).Localize.prefetch(language);
};

const translate = (
    value?: string,
    variables?: {},
    callback?: (value: string) => void,
) => {
    if (!value) return '';
    let newValue: string = '';
    (window as any).Localize.translate(value, variables, (translation: any) => {
        newValue = translation;
        callback?.(translation);
    });

    return newValue || value;
};

/**
 * Sorts an array of objects based on the given property's value and the current locale.
 * Does not translate the original value itself.
 * @param arr - array of objects
 * @param sortProperty - the object property key for the value on which the sort should be done
 * @param numeric - OPTIONAL if true the sort is numerical
 * @returns the sorted array
 */
const useSortStringForLocale = <T extends Record<string, unknown>>(
    arr: T[],
    sortProperty: keyof T,
    numeric?: boolean,
): T[] => {
    const {selectedLanguage} = useLanguage();

    if (!arr || !Array.isArray(arr)) return [];
    if (arr.some(e => e[sortProperty] === undefined)) return arr;

    const getLocaleCompareLocale = () => {
        try {
            'foo'.localeCompare('bar', 'i');
        } catch (e) {
            if ((e as Error).name === 'RangeError') {
                return selectedLanguage;
            }
        }
        return undefined;
    };

    const locale = getLocaleCompareLocale();

    return arr.sort((a, b) => {
        if (typeof a[sortProperty] === 'string') {
            return (a[sortProperty] as string)
                .toLowerCase()
                .localeCompare(
                    (b[sortProperty] as string).toLowerCase(),
                    locale,
                    {
                        numeric,
                    },
                );
        }
        if (numeric) {
            return (a[sortProperty] as number) - (b[sortProperty] as number);
        }
        return a[sortProperty] < b[sortProperty] ? -1 : 1;
    });
};

const translateDropdownOptions = <T extends {}>(
    arr: T[],
    labelKey: keyof T,
): T[] => {
    return arr.map(e => ({...e, [labelKey]: translate(e[labelKey] as string)}));
};

export {
    getAvailableLocalizeLanguages,
    getLocalizeLanguage,
    setLocalizeLanguage,
    translate,
    useSortStringForLocale,
    prefetchLocalizeLanguage,
    translateDropdownOptions,
};
